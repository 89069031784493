<div class="pt-1" *ngIf="Auth.getUserRole()">
  <div class="d-flex justify-content-between my-3">
    <span class="fs-30 fw-700">Dashboard</span>

    <div class="d-flex align-items-center" id="refreshButton">
      <div class="refresh">
        <img
          src="../../assets/images/Group 19201.svg"
          (click)="refreshPage()"
          alt=""
        />
      </div>
      <div class="position-relative">
        <img
          src="../../assets/images/search.svg"
          class="d-block input-icon-right"
        />
        <input
          type="text"
          (ngModelChange)="searchAllProject($event)"
          [(ngModel)]="searchAll"
          class="fs-14 form-control search-projects"
          placeholder="Search Projects"
        />
      </div>
    </div>
  </div>

  <div class="table-responsive table-scroll-bar">
    <table class="table border-0" id="mytable">
      <thead id="tableHead">
        <tr class="bg-white shadow-sm table-row">
          <!-- Project Name -->
          <th class="border-0 th_project_name">
            <div
              class="d-flex align-items-center justify-content-between cursor-pointer name0"
            >
              <span
                class="header-names head project2"
                (click)="reverseSortByProject()"
                >Project Name</span
              >
              <div class="Sortarrows d-flex flex-column">
                <div *ngIf="sortIcon5">
                  <img
                    (click)="reverseSortByProject()"
                    src="../../assets/images/upDownArrow.svg"
                    alt=""
                  />
                </div>
                <div *ngIf="sortIconShow6">
                  <div class="sort1">
                    <img
                      class="arrows"
                      *ngIf="eSort == true"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByProject()"
                      alt=""
                    />
                  </div>
                  <div class="mt-2">
                    <img
                      class="arrows"
                      *ngIf="eSort == false"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByProject()"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </th>

          <!-- Release Date -->
          <th class="border-0">
            <div
              class="d-flex align-items-center justify-content-between cursor-pointer name0"
            >
              <span
                class="header-names head project2"
                (click)="reverseSortByDueDate()"
                >Release Date</span
              >
              <div class="Sortarrows d-flex flex-column">
                <div *ngIf="sortIcon14">
                  <img
                    (click)="reverseSortByDueDate()"
                    src="../../assets/images/upDownArrow.svg"
                    alt=""
                  />
                </div>
                <div *ngIf="sortIconShow15">
                  <div [ngClass]="oSort ? 'sort1' : ''">
                    <img
                      class="arrows"
                      [src]="
                        oSort
                          ? 'assets/images/Path 61663.svg'
                          : 'assets/images/Path 61663.svg'
                      "
                      (click)="reverseSortByDueDate()"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </th>

          <!-- Priority -->
          <th class="border-0">
            <div
              class="d-flex align-items-center justify-content-between cursor-pointer"
            >
              <span
                class="header-names w-2"
                (click)="reverseSortByPriority()"
                id="prior"
                >Priority
              </span>
              <div class="Sortarrows d-flex flex-column">
                <div *ngIf="sortIcon">
                  <img
                    (click)="reverseSortByPriority()"
                    src="../../assets/images/upDownArrow.svg"
                    alt=""
                  />
                </div>
                <div *ngIf="sortIconShow">
                  <div class="sort1">
                    <img
                      class="arrows"
                      *ngIf="isSort == true"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByPriority()"
                      alt=""
                    />
                  </div>
                  <div class="mt-2">
                    <img
                      class="arrows"
                      *ngIf="isSort == false"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByPriority()"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </th>

          <!-- Overall project status -->
          <th class="border-0">
            <div
              class="d-flex align-items-center justify-content-between cursor-pointer"
            >
              <span
                class="header-names project1"
                (click)="reverseSortByOverallStatus()"
                id="project_status"
                >Overall Project Status
              </span>
              <div class="Sortarrows d-flex flex-column">
                <div *ngIf="sortIcon15">
                  <img
                    (click)="reverseSortByOverallStatus()"
                    src="../../assets/images/upDownArrow.svg"
                    alt=""
                  />
                </div>
                <div *ngIf="sortIconShow16">
                  <div class="sort1">
                    <img
                      class="arrows"
                      *ngIf="pSort == true"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByOverallStatus()"
                      alt=""
                    />
                  </div>
                  <div class="mt-2">
                    <img
                      class="arrows"
                      *ngIf="pSort == false"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByOverallStatus()"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </th>

          <!-- Current Status -->
          <th class="border-0">
            <div
              class="d-flex align-items-center justify-content-between cursor-pointer names"
            >
              <span class="header-names1" (click)="reverseSortByStatus()"
                >Current Status</span
              >
              <div class="Sortarrows d-flex flex-column">
                <div *ngIf="sortIcon1">
                  <img
                    (click)="reverseSortByStatus()"
                    src="../../assets/images/upDownArrow.svg"
                    alt=""
                  />
                </div>
                <div *ngIf="sortIconShow2">
                  <div class="sort1">
                    <img
                      class="arrows"
                      *ngIf="aSort == true"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByStatus()"
                      alt=""
                    />
                  </div>
                  <div class="mt-2">
                    <img
                      class="arrows"
                      *ngIf="aSort == false"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByStatus()"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </th>

          <!-- Responsibility -->
          <th class="border-0">
            <div
              class="d-flex align-items-center justify-content-between cursor-pointer"
            >
              <span class="header-names3" (click)="reverseSortByResponsibilty()"
                >Responsibility</span
              >
              <div class="Sortarrows d-flex flex-column">
                <div *ngIf="sortIcon3">
                  <img
                    (click)="reverseSortByResponsibilty()"
                    src="../../assets/images/upDownArrow.svg"
                    alt=""
                  />
                </div>

                <div *ngIf="sortIconShow4">
                  <div class="sort1">
                    <img
                      class="arrows"
                      *ngIf="cSort == true"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByResponsibilty()"
                      alt=""
                    />
                  </div>
                  <div class="mt-2">
                    <img
                      class="arrows"
                      *ngIf="cSort == false"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByResponsibilty()"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </th>

          <!-- Customer Name -->
          <th class="border-0">
            <div
              class="d-flex align-items-center justify-content-between cursor-pointer"
            >
              <span
                class="header-names project1"
                (click)="reverseSortByClient()"
                >Customer Name</span
              >
              <div class="Sortarrows d-flex flex-column">
                <div *ngIf="sortIcon4">
                  <img
                    (click)="reverseSortByClient()"
                    src="../../assets/images/upDownArrow.svg"
                    alt=""
                  />
                </div>
                <div *ngIf="sortIconShow5">
                  <div class="sort1">
                    <img
                      class="arrows"
                      *ngIf="dSort == true"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByClient()"
                      alt=""
                    />
                  </div>
                  <div class="mt-2">
                    <img
                      class="arrows"
                      *ngIf="dSort == false"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByClient()"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </th>

          <!-- Supplier Name -->
          <th class="border-0">
            <div
              class="d-flex align-items-center justify-content-between cursor-pointer"
            >
              <span
                class="header-names project1"
                (click)="reverseSortBySupplier()"
                >Supplier Name</span
              >
              <div class="Sortarrows d-flex flex-column">
                <div *ngIf="sortIcon8">
                  <img
                    (click)="reverseSortBySupplier()"
                    src="../../assets/images/upDownArrow.svg"
                    alt=""
                  />
                </div>
                <div *ngIf="sortIconShow9">
                  <div class="sort1">
                    <img
                      class="arrows"
                      *ngIf="hSort == true"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortBySupplier()"
                      alt=""
                    />
                  </div>
                  <div class="mt-2">
                    <img
                      class="arrows"
                      *ngIf="hSort == false"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortBySupplier()"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </th>

          <!-- Supplier Category -->
          <th class="border-0">
            <div
              class="d-flex align-items-center justify-content-between cursor-pointer"
            >
              <span
                class="header-names project1"
                (click)="reverseSortBySupplierCategory()"
                >Supplier Category</span
              >
              <div class="Sortarrows d-flex flex-column">
                <div *ngIf="sortIcon12">
                  <img
                    (click)="reverseSortBySupplierCategory()"
                    src="../../assets/images/upDownArrow.svg"
                    alt=""
                  />
                </div>
                <div *ngIf="sortIconShow13">
                  <div [ngClass]="mSort ? 'sort1' : ''">
                    <img
                      class="arrows"
                      [src]="
                        mSort
                          ? 'assets/images/Path 61663.svg'
                          : 'assets/images/Path 61663.svg'
                      "
                      (click)="reverseSortBySupplierCategory()"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </th>

          <!-- Supplier Sub-Category -->
          <th class="border-0">
            <div
              class="d-flex align-items-center justify-content-between cursor-pointer"
            >
              <span
                class="header-names project1"
                (click)="reverseSortBySupplierSubCategory()"
                >Supplier Sub-Category</span
              >
              <div class="Sortarrows d-flex flex-column">
                <div *ngIf="sortIcon13">
                  <img
                    (click)="reverseSortBySupplierSubCategory()"
                    src="../../assets/images/upDownArrow.svg"
                    alt=""
                  />
                </div>
                <div *ngIf="sortIconShow14">
                  <div [ngClass]="nSort ? 'sort1' : ''">
                    <img
                      class="arrows"
                      [src]="
                        nSort
                          ? 'assets/images/Path 61663.svg'
                          : 'assets/images/Path 61663.svg'
                      "
                      (click)="reverseSortBySupplierSubCategory()"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </th>

          <!-- Short Summary of Status -->
          <th class="border-0 shortSummaryStatus">
            <div
              class="d-flex align-items-center justify-content-between cursor-pointer name2"
            >
              <span class="header-names" (click)="reverseSortBySummary()"
                >Short Summary of Status</span
              >
              <div class="Sortarrows d-flex flex-column">
                <div *ngIf="sortIcon6">
                  <img
                    (click)="reverseSortBySummary()"
                    src="../../assets/images/upDownArrow.svg"
                    alt=""
                  />
                </div>
                <div *ngIf="sortIconShow7">
                  <div class="sort1">
                    <img
                      class="arrows"
                      *ngIf="fSort == true"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortBySummary()"
                      alt=""
                    />
                  </div>
                  <div class="mt-2">
                    <img
                      class="arrows"
                      *ngIf="fSort == false"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortBySummary()"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </th>
          <!-- <th class="border-0 blockage1">
            <div
              class="d-flex align-items-center justify-content-between cursor-pointer name1"
            >
              <span
                class="header-names project2"
                (click)="reverseSortByBlockages()"
                >Blockages</span
              >
              <div class="Sortarrows d-flex flex-column">
                <div *ngIf="sortIcon7">
                  <img
                    (click)="reverseSortByBlockages()"
                    src="../../assets/images/upDownArrow.svg"
                    alt=""
                  />
                </div>
                <div *ngIf="sortIconShow8">
                  <div class="sort1">
                    <img
                      class="arrows"
                      *ngIf="gSort == true"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByBlockages()"
                      alt=""
                    />
                  </div>
                  <div class="mt-2">
                    <img
                      class="arrows"
                      *ngIf="gSort == false"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByBlockages()"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </th>
          <th class="border-0">
            <div
              class="d-flex align-items-center justify-content-between cursor-pointer name3"
            >
              <span class="header-names" (click)="reverseSortByNextStep()"
                >Next Step forward</span
              >
              <div class="Sortarrows d-flex flex-column">
                <div *ngIf="sortIcon9">
                  <img
                    (click)="reverseSortByNextStep()"
                    src="../../assets/images/upDownArrow.svg"
                    alt=""
                  />
                </div>
                <div *ngIf="sortIconShow10">
                  <div class="sort1">
                    <img
                      class="arrows"
                      *ngIf="jSort == true"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByNextStep()"
                      alt=""
                    />
                  </div>
                  <div class="mt-2">
                    <img
                      class="arrows"
                      *ngIf="jSort == false"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByNextStep()"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </th> -->

          <!-- Project Description -->
          <th class="border-0">
            <div
              class="d-flex align-items-center justify-content-between cursor-pointer name3"
            >
              <span
                class="header-names"
                (click)="reverseSortByProjectDescription()"
                >Project Description</span
              >
              <div class="Sortarrows d-flex flex-column">
                <div *ngIf="sortIcon10">
                  <img
                    src="../../assets/images/upDownArrow.svg"
                    (click)="reverseSortByProjectDescription()"
                    alt=""
                  />
                </div>
                <div *ngIf="sortIconShow11">
                  <div class="sort1">
                    <img
                      class="arrows"
                      *ngIf="kSort == true"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByProjectDescription()"
                      alt=""
                    />
                  </div>
                  <div class="mt-2">
                    <img
                      class="arrows"
                      *ngIf="kSort == false"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByProjectDescription()"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </th>

          <!-- Message To Team -->
          <th class="border-0">
            <div
              class="d-flex align-items-center justify-content-between cursor-pointer name3"
            >
              <span class="header-names" (click)="reverseSortByMessageToTeam()"
                >Message To Team</span
              >
              <div class="Sortarrows d-flex flex-column">
                <div *ngIf="sortIcon11">
                  <img
                    src="../../assets/images/upDownArrow.svg"
                    (click)="reverseSortByMessageToTeam()"
                    alt=""
                  />
                </div>
                <div *ngIf="sortIconShow12">
                  <div class="sort1">
                    <img
                      class="arrows"
                      *ngIf="lSort == true"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByMessageToTeam()"
                      alt=""
                    />
                  </div>
                  <div class="mt-2">
                    <img
                      class="arrows"
                      *ngIf="lSort == false"
                      src="../../assets/images/Path 61663.svg"
                      (click)="reverseSortByMessageToTeam()"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </th>
        </tr>
        <tr class="bg-white">
          <!-- Project Name -->
          <th class="border-0">
            <div class="position-relative">
              <img
                src="../../assets/images/search.svg"
                class="d-block input-icon-right"
              />
              <input
                type="text"
                (ngModelChange)="searchProjects($event)"
                [(ngModel)]="searchProject"
                class="fs-14 form-control search searchprojects text-truncate levels1"
                id="Search"
                placeholder="Search"
              />
            </div>
          </th>

          <!-- for release date -->
          <th class="border-0">
            <input
              type="date"
              (click)="searchByDueDate($event)"
              [(ngModel)]="dueDate"
              name="Due_date"
              class="fs-14 form-control search searchprojects text-truncate levels1"
              id="Search"
              placeholder="mm/dd/yy"
              format="mm/dd/yyyy"
            />
          </th>

          <!-- Priority -->
          <th class="border-0" style="min-width: 10rem">
            <div>
              <!-- <div class="dropdown">
                <div
                  class="btn dropdown-toggle d-flex justify-content-between filter-opt dropdown1"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="filter1">
                    {{ filterCategories.priority || "Filter" }}
                  </span>
                  <img src="../../assets/images/Path 61663.svg" alt="" />
                </div>
                <div
                  class="dropdown-menu dropdown-menu1 filter-list dropdown cursor-pointer"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a (click)="range($event)" class="dropdown-item"
                    ><span>No Filter</span></a
                  >
                  <a (click)="range($event)" class="dropdown-item"
                    ><span>High</span></a
                  >
                  <a (click)="range($event)" class="dropdown-item"
                    ><span>Medium</span></a
                  >
                  <a (click)="range($event)" class="dropdown-item"
                    ><span>Low</span></a
                  >
                </div>
              </div> -->
              <ng-select
                class="gridster-item-content fs-18 fw-400 custom-scroll-bar"
                name="project_priority"
                [multiple]="true"
                [clearable]="true"
                placeholder="Filter"
                (change)="range(multiFilter.filterPriorities)"
                [(ngModel)]="multiFilter.filterPriorities"
              >
                <ng-option
                  class="custom_ngSelect custom-scroll-bar"
                  *ngFor="let x of project_priorities"
                  [value]="x"
                  >{{ x }}</ng-option
                >
              </ng-select>
            </div>
          </th>
          <!-- overall_project_status -->
          <th class="border-0">
            <div>
              <!-- <div class="dropdown">
                <div
                  class="btn dropdown-toggle d-flex justify-content-between filter-opt dropdown1"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="filter7">
                    {{ filterCategories.overall_project_status || "Filter" }}
                  </span>
                  <img src="../../assets/images/Path 61663.svg" alt="" />
                </div>
                <div
                  class="dropdown-menu dropdown-menu1 filter-list dropdown cursor-pointer"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a (click)="overallStatus($event)" class="dropdown-item"
                    ><span>No Filter</span></a
                  >
                  <a (click)="overallStatus($event)" class="dropdown-item"
                    ><span>Ideation</span></a
                  >
                  <a (click)="overallStatus($event)" class="dropdown-item"
                    ><span>Active</span></a
                  >
                  <a (click)="overallStatus($event)" class="dropdown-item"
                    ><span>On Hold</span></a
                  >
                  <a (click)="overallStatus($event)" class="dropdown-item"
                    ><span>Completed</span></a
                  >
                </div>
              </div> -->

              <ng-select
                class="gridster-item-content fs-18 fw-400 custom-scroll-bar"
                name="project_priority"
                [multiple]="true"
                [clearable]="true"
                placeholder="Filter"
                (change)="overallStatus(multiFilter.overAllStatus)"
                [(ngModel)]="multiFilter.overAllStatus"
              >
                <ng-option
                  class="custom_ngSelect custom-scroll-bar"
                  *ngFor="let x of removeArchive(overall_project_status)"
                  [value]="x"
                  >{{ x }}</ng-option
                >
              </ng-select>
            </div>
          </th>

          <!-- Current Status -->
          <th class="border-0">
            <div style="min-width: 8rem">
              <!-- <div class="dropdown">
                <div
                  class="btn dropdown-toggle d-flex justify-content-between filter-opt2 dropdown2"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="filter2">
                    {{ filterCategories.currentStatus || "Filter" }}
                  </span>
                  <img src="../../assets/images/Path 61663.svg" alt="" />
                </div>
                <div
                  class="dropdown-menu filter-list2 list-3 dropdown cursor-pointer"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a (click)="time($event)" class="dropdown-item"
                    ><span>No Filter</span></a
                  >
                  <a (click)="time($event)" class="dropdown-item"
                    ><span>On Time</span></a
                  >
                  <a (click)="time($event)" class="dropdown-item"
                    ><span>Late</span></a
                  >
                  <a (click)="time($event)" class="dropdown-item"
                    ><span>At Risk</span></a
                  >
                </div>
              </div> -->

              <ng-select
                class="gridster-item-content fs-18 fw-400 custom-scroll-bar"
                name="project_priority"
                [multiple]="true"
                [clearable]="true"
                placeholder="Filter"
                (change)="time(multiFilter.currentStatus)"
                [(ngModel)]="multiFilter.currentStatus"
              >
                <ng-option
                  class="custom_ngSelect custom-scroll-bar"
                  *ngFor="let x of projectCurrentStatus"
                  [value]="x"
                  >{{ x }}</ng-option
                >
              </ng-select>
            </div>
          </th>

          <!-- Responsibility -->
          <th class="border-0">
            <div style="min-width: 8rem">
              <!-- <div class="dropdown">
                <div
                  class="btn dropdown-toggle justify-content-between d-flex filter-opt4 dropdown4"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="filter4 text-truncate">
                    {{ filterCategories.responsibility || "Filter" }}
                  </span>
                  <img src="../../assets/images/Path 61663.svg" alt="" />
                </div>
                <div
                  class="dropdown-menu filter-list4 dropdown list-1 cursor-pointer"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a (click)="accountManager($event)" class="dropdown-item"
                    ><span class="dropdownitem">No Filter</span></a
                  >
                  <div
                    class="Project-dropdown1"
                    *ngFor="let x of filterResponsibilityName()"
                  >
                    <a
                      class="dropdown-item d-flex align-items-center justify-content-between"
                      (click)="accountManager($event)"
                      ><span>{{ x }}</span></a
                    >
                  </div>
                </div>
              </div> -->

              <ng-select
                class="gridster-item-content fs-18 fw-400 custom-scroll-bar"
                name="project_priority"
                [multiple]="true"
                [clearable]="true"
                placeholder="Filter"
                (change)="accountManager(multiFilter.accountManager)"
                [(ngModel)]="multiFilter.accountManager"
              >
                <ng-option
                  class="custom_ngSelect custom-scroll-bar"
                  *ngFor="let x of filterResponsibilityName()"
                  [value]="x"
                  >{{ x }}</ng-option
                >
              </ng-select>
            </div>
          </th>

          <!-- Customer Name -->
          <th class="border-0">
            <!-- <div class="dropdown">
              <div
                class="btn dropdown-toggle d-flex justify-content-between filter-opt5 dropdown4"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="filter5 text-truncate">
                  {{ filterCategories.clinetName || "Filter" }}
                </span>
                <img src="../../assets/images/Path 61663.svg" alt="" />
              </div>
              <div
                class="dropdown-menu filter-list5 dropdown list-2 cursor-pointer"
                aria-labelledby="dropdownMenuButton"
              >
                <a (click)="companyName($event)" class="dropdown-item"
                  ><span class="dropdownitem">No Filter</span></a
                >
                <div
                  class="Project-dropdown2"
                  *ngFor="let x of filterCompanyName()"
                >
                  <a class="dropdown-item" (click)="companyName($event)"
                    ><span>{{ x }}</span></a
                  >
                </div>
              </div>
            </div> -->

            <ng-select
              class="gridster-item-content fs-18 fw-400 custom-scroll-bar"
              name="project_priority"
              [multiple]="true"
              [clearable]="true"
              placeholder="Filter"
              (change)="companyName(multiFilter.companyName)"
              [(ngModel)]="multiFilter.companyName"
            >
              <ng-option
                class="custom_ngSelect custom-scroll-bar"
                *ngFor="let x of filterCompanyName()"
                [value]="x"
                >{{ x }}</ng-option
              >
            </ng-select>
          </th>

          <!-- Supplier Name -->
          <th class="border-0">
            <!-- <div class="dropdown">
              <div
                class="btn dropdown-toggle d-flex justify-content-between filter-opt5 dropdown4"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="filter6 text-truncate"> Filter </span>
                <img src="../../assets/images/Path 61663.svg" alt="" />
              </div>
              <div
                class="dropdown-menu filter-list5 dropdown list-2 cursor-pointer"
                aria-labelledby="dropdownMenuButton"
              >
                <a (click)="supplierName($event)" class="dropdown-item"
                  ><span class="dropdownitem">No Filter</span></a
                >
                <div
                  class="Project-dropdown2"
                  *ngFor="let x of filterSupplierName()"
                >
                  <a class="dropdown-item" (click)="supplierName($event)"
                    ><span>{{ x }}</span></a
                  >
                </div>
              </div>
            </div> -->

            <ng-select
              class="gridster-item-content fs-18 fw-400 custom-scroll-bar"
              name="project_priority"
              [multiple]="true"
              [clearable]="true"
              placeholder="Filter"
              (change)="supplierName(multiFilter.supplierName)"
              [(ngModel)]="multiFilter.supplierName"
            >
              <ng-option
                class="custom_ngSelect custom-scroll-bar"
                *ngFor="let x of filterSupplierName()"
                [value]="x"
                >{{ x }}</ng-option
              >
            </ng-select>
          </th>

          <!-- Supplier Category -->
          <th class="border-0">
            <div class="position-relative">
              <img
                src="../../assets/images/search.svg"
                class="d-block input-icon-right"
              />
              <input
                type="text"
                class="fs-14 form-control search text-truncate levels"
                id="Search"
                (ngModelChange)="supplierCategorySearch($event)"
                [(ngModel)]="supplierCategory"
                placeholder="Search"
              />
            </div>
          </th>

          <!-- Supplier Sub-Category -->
          <th class="border-0">
            <div class="position-relative">
              <img
                src="../../assets/images/search.svg"
                class="d-block input-icon-right"
              />
              <input
                type="text"
                class="fs-14 form-control search text-truncate levels"
                id="Search"
                (ngModelChange)="supplierSubCategorySearch($event)"
                [(ngModel)]="supplierSubCategory"
                placeholder="Search"
              />
            </div>
          </th>

          <!-- Summary of Status -->
          <th class="border-0">
            <div class="position-relative status1">
              <img
                src="../../assets/images/search.svg"
                class="d-block input-icon-right searchIcon"
              />
              <input
                type="text"
                (ngModelChange)="SummaryStatus($event)"
                [(ngModel)]="Status"
                class="fs-14 form-control search text-truncate levels"
                id="Search"
                placeholder="Search"
              />
            </div>
          </th>
          <!-- <th class="border-0">
            <div class="position-relative">
              <img
                src="../../assets/images/search.svg"
                class="d-block input-icon-right"
              />
              <input
                type="text"
                (ngModelChange)="blockages($event)"
                [(ngModel)]="blockage"
                class="fs-14 form-control search text-truncate levels"
                id="Search"
                placeholder="Search"
              />
            </div>
          </th>
          <th class="border-0">
            <div class="position-relative">
              <img
                src="../../assets/images/search.svg"
                class="d-block input-icon-right"
              />
              <input
                type="text"
                (ngModelChange)="nextStep($event)"
                [(ngModel)]="nextSteps"
                class="fs-14 form-control search text-truncate levels"
                id="Search"
                placeholder="Search"
              />
            </div>
          </th> -->

          <!-- Project Description -->
          <th class="border-0">
            <div class="position-relative">
              <img
                src="../../assets/images/search.svg"
                class="d-block input-icon-right"
              />
              <input
                type="text"
                class="fs-14 form-control search text-truncate levels"
                id="Search"
                (ngModelChange)="projectDescription($event)"
                [(ngModel)]="project_description"
                placeholder="Search"
              />
            </div>
          </th>

          <!-- Message To Team -->
          <th class="border-0">
            <div class="position-relative">
              <img
                src="../../assets/images/search.svg"
                class="d-block input-icon-right"
              />
              <input
                type="text"
                class="fs-14 form-control search text-truncate levels"
                id="Search"
                (ngModelChange)="messageToTeam($event)"
                [(ngModel)]="message_to_team"
                placeholder="Search"
              />
            </div>
          </th>
        </tr>
      </thead>

      <div class="p-1 spaceBetween"></div>

      <tbody class="bg-white">
        <tr class="border-bottom cursor-pointer" *ngFor="let x of projectList">
          <td
            class="text-center"
            (click)="goToTask(x)"
            mat-raised-button
            matTooltip="{{ x?.name }}"
          >
            <div
              class="fs-20 color-secondary text-center cursor-pointer d-flex justify-content-center align-items-center flex-column"
            >
              <span class="project_name text-truncate">{{ x?.name }}</span>

              <span *ngIf="projectDuration(x?.due_date)">
                <span class="badge badge-success" *ngIf="status == 'ontime'"
                  >ON TIME</span
                >
                <span class="badge badge-warning" *ngIf="status == 'atRisk'"
                  >AT RISK</span
                >
                <span class="badge badge-danger" *ngIf="status == 'late'"
                  >LATE</span
                >
              </span>
            </div>
          </td>

          <td class="due_date">
            <span
              class="fs-20 color-secondary cursor-pointer d-flex justify-content-center align-items-center"
            >
              <div
                (click)="openDatePicker(x)"
                class="px-2 py-1 border rounded w-100 fs-18 dateimage"
              >
                {{ x?.due_date | date : "MM/dd/yy" }}
              </div>

              <input
                class="form-control cursor-pointer fs-18 w-1 invisible p-0"
                type="text"
                [id]="x._id"
                autocomplete="off"
                [minDate]="minDate"
                bsDatepicker
                [class]="x._id"
                [outsideClick]="true"
                placement="left"
                [bsConfig]="bsConfig"
                [(ngModel)]="x.due_date"
                (ngModelChange)="updateProject(x)"
                #bsDatepicker="ngModel"
                name="datepicker"
                placeholder="Choose Release Date"
              />
            </span>
          </td>
          <td class="timing border-0 cursor-pointer">
            <!-- <span
              class="bg-success-active priority mr-50 fs-18 px-3 py-2 d-flex justify-content-center align-items-center"
              *ngIf="x.project_priority != null"
              [ngStyle]="{
                color:
                  x.project_priority == 'High'
                    ? '#FF4343'
                    : x.project_priority == 'Low'
                    ? '#44BF1F'
                    : x.project_priority == 'Medium'
                    ? '#FFA700'
                    : x.project_priority == ''
                    ? '#fff'
                    : '#000',

                'background-color':
                  x.project_priority == 'High'
                    ? '#FAEFEF'
                    : x.project_priority == 'Low'
                    ? '#F1FAEF'
                    : x.project_priority == 'Medium'
                    ? '#FFF9E6'
                    : x.project_priority == ''
                    ? '#fff'
                    : '#000'
              }"
              >{{ x.project_priority }}</span
            > -->

            <ng-select
              class="gridster-item-content fs-18 fw-400 custom-scroll-bar"
              name="project_priority"
              (change)="updateProject(x)"
              [clearable]="false"
              [(ngModel)]="x.project_priority"
            >
              <ng-option
                class="custom_ngSelect custom-scroll-bar"
                *ngFor="let x of project_priorities"
                [value]="x"
                >{{ x }}</ng-option
              >
            </ng-select>
          </td>
          <td class="timing border-0 cursor-pointer">
            <ng-select
              class="gridster-item-content fs-18 fw-400 custom-scroll-bar"
              name="project_priority"
              (change)="updateProject(x)"
              [clearable]="false"
              [(ngModel)]="x.overall_project_status"
            >
              <ng-option
                class="custom_ngSelect custom-scroll-bar"
                *ngFor="let x of overall_project_status"
                [value]="x"
                >{{ x }}</ng-option
              >
            </ng-select>
          </td>

          <td>
            <span
              class="bg-success-active timing mr-50 fs-18 px-3 py-2 cursor-pointer d-flex justify-content-center align-items-center"
              [ngStyle]="{
                color:
                  x.schedule_status == 'On Time'
                    ? '#44BF1F'
                    : x.schedule_status == 'At Risk'
                    ? '#FFA700'
                    : x.schedule_status == 'Late'
                    ? '#FF4343'
                    : x.schedule_status == ''
                    ? '#fff'
                    : '#000',
                'background-color':
                  x.schedule_status == 'On Time'
                    ? '#F1FAEF'
                    : x.schedule_status == 'At Risk'
                    ? '#FFF9E6'
                    : x.schedule_status == 'Late'
                    ? '#FAEFEF'
                    : x.schedule_status == ''
                    ? '#fff'
                    : '#000'
              }"
              *ngIf="x.schedule_status != null"
              >{{ x.schedule_status }}</span
            >
          </td>
          <!-- <td>
            <span
              class="fs-20 color-secondary text-center cursor-pointer d-flex justify-content-center align-items-center"
              >{{ x?.current_project_stage }}</span
            >
          </td> -->
          <td>
            <span
              class="fs-20 color-secondary text-center cursor-pointer d-flex justify-content-center align-items-center"
              >{{
                (x.account_manager
                  ? x.account_manager.name + " " + x.account_manager.last_name
                  : null
                ) | titlecase
              }}</span
            >
          </td>
          <td>
            <span
              class="fs-20 color-secondary text-center cursor-pointer d-flex justify-content-center align-items-center"
              >{{ x?.company_name?.company_name }}</span
            >
          </td>
          <td>
            <span
              class="fs-20 color-secondary text-center cursor-pointer d-flex justify-content-center align-items-center"
              >{{ x?.supplier[0]?.company_name }}</span
            >
          </td>
          <td>
            <span
              class="fs-20 color-secondary text-center cursor-pointer d-flex justify-content-center align-items-center"
              >{{ x?.supplier[0]?.category }}</span
            >
          </td>
          <td>
            <span
              class="fs-20 color-secondary text-center cursor-pointer d-flex justify-content-center align-items-center"
              >{{ x?.supplier[0]?.sub_category }}</span
            >
          </td>

          <td class="Summaryofstatus">
            <!-- <span
              class="fs-20 color-secondary text-center cursor-pointer justify-content-center align-items-center truncate"
              >{{ x?.summary_of_status }}</span
            > -->

            <!-- Summary of status array starts -->
            <span
              class="fs-20 color-secondary cursor-pointer justify-content-center align-items-center truncate"
              *ngFor="let item of x?.summary | slice : 0 : 2"
              (click)="openSummary(x.summary)"
            >
              <span class="truncate-2 mb-1">
                {{ item?.summary_of_status }}
              </span>
            </span>
            <!-- Summary of status array end -->
          </td>
          <!-- <td>
            <span
              class="fs-20 color-secondary text-center cursor-pointer justify-content-center align-items-center truncate"
              >{{ x?.blockages }}</span
            >
          </td>
          <td>
            <span
              class="fs-20 color-secondary text-center cursor-pointer justify-content-center align-items-center truncate"
              >{{ x?.next_step }}</span
            >
          </td> -->
          <td>
            <span
              class="fs-20 color-secondary text-center cursor-pointer justify-content-center align-items-center truncate"
              >{{ x?.project_description }}</span
            >
          </td>
          <td>
            <span
              class="fs-20 color-secondary text-center cursor-pointer justify-content-center align-items-center truncate"
              >{{ x?.message_to_team }}</span
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="pt-3" *ngIf="!Auth.getUserRole()">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <span class="fs-30 text-color-dark fw-700">{{
          userData.companyNameClient.company_name
            ? userData.companyNameClient.company_name
            : ""
        }}</span>
      </div>
      <div class="col-xl-5 col-sm-12 my-1">
        <div class="p-2 bg-white rounded">
          <div class="d-flex justify-content-between px-2 py-1">
            <div class="color-faint-gray fs-20 fw-600">Approvals Required</div>
          </div>

          <div class="custom-scroll-bar1 groups-custom-height">
            <div
              class="d-flex align-items-center justify-content-between my-1 rounded bg-gray p-2 cursor-pointer"
            >
              <span class="mb-0 fs-16 text-color-dark fw-700">
                1. Samples
              </span>
              <div class="d-flex justify-content-between">
                <span
                  class="fs-16 font-bold font-bold border border-orange rounded-pill px-2 py-1 color-faint-orange"
                  >Approval
                </span>
                <span class="ml-3"
                  ><img
                    src="../../assets/images/attach.svg"
                    style="width: 0.9rem"
                /></span>
                <span class="ml-3"
                  ><img
                    src="../../assets/images/pdf-file.svg"
                    style="width: 0.9rem"
                /></span>
                <span class="ml-3"
                  ><img
                    src="../../assets/images/view.svg"
                    style="width: 0.9rem"
                /></span>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-between my-1 rounded bg-gray p-2 cursor-pointer"
            >
              <span class="mb-0 text-color-dark fs-16 fw-700">
                2. Cost Approval
              </span>
              <div class="d-flex justify-content-between">
                <span
                  class="fs-16 font-bold font-bold border border-orange rounded-pill px-2 py-1 color-faint-orange"
                  >Approval
                </span>
                <span class="ml-3"
                  ><img
                    src="../../assets/images/attach.svg"
                    style="width: 0.9rem"
                /></span>
                <span class="ml-3"
                  ><img
                    src="../../assets/images/pdf-file.svg"
                    style="width: 0.9rem"
                /></span>
                <span class="ml-3"
                  ><img
                    src="../../assets/images/view.svg"
                    style="width: 0.9rem"
                /></span>
              </div>
            </div>
            <div class="d-flex justify-content-between px-2 py-2">
              <div class="color-faint-gray fs-20 fw-600">
                Approvals Required
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-between my-1 rounded bg-gray p-2 cursor-pointer"
            >
              <span class="mb-0 text-color-dark fs-16 fw-700">
                1. Send Inspo Pics to BF
              </span>
              <div class="d-flex justify-content-between">
                <span class="ml-3"
                  ><img
                    src="../../assets/images/attach.svg"
                    style="width: 0.9rem"
                /></span>
                <span class="ml-3"
                  ><img
                    src="../../assets/images/pdf-file.svg"
                    style="width: 0.9rem"
                /></span>
                <span class="ml-3"
                  ><img
                    src="../../assets/images/view.svg"
                    style="width: 0.9rem"
                /></span>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-between my-1 rounded bg-gray p-2 cursor-pointer"
            >
              <span class="mb-0 text-color-dark fs-16 fw-700">
                2. Cost Approval
              </span>
              <div class="d-flex justify-content-between">
                <span class="ml-3"
                  ><img
                    src="../../assets/images/attach.svg"
                    style="width: 0.9rem"
                /></span>
                <span class="ml-3"
                  ><img
                    src="../../assets/images/pdf-file.svg"
                    style="width: 0.9rem"
                /></span>
                <span class="ml-3"
                  ><img
                    src="../../assets/images/view.svg"
                    style="width: 0.9rem"
                /></span>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-between my-1 rounded bg-gray p-2 cursor-pointer"
            >
              <span class="mb-0 text-color-dark fs-16 fw-700">
                2. Cost Approval
              </span>
              <div class="d-flex justify-content-between">
                <span class="ml-3"
                  ><img
                    src="../../assets/images/attach.svg"
                    style="width: 0.9rem"
                /></span>
                <span class="ml-3"
                  ><img
                    src="../../assets/images/pdf-file.svg"
                    style="width: 0.9rem"
                /></span>
                <span class="ml-3"
                  ><img
                    src="../../assets/images/view.svg"
                    style="width: 0.9rem"
                /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-7 my-1">
        <div class="row">
          <div class="col-md-6">
            <div class="p-2 bg-white rounded">
              <div class="d-flex justify-content-between">
                <div class="d-flex align-item-start">
                  <div class="mb-0 color-title fs-18 font-bold">
                    Inbound Orders
                  </div>
                </div>

                <div class="col-md-7">
                  <span class="mat-datePicker">
                    <mat-form-field appearance="fill" class="border-0 p-0">
                      <!-- <mat-label>Inbound</mat-label> -->
                      <mat-date-range-input
                        [formGroup]="campaignOne"
                        [rangePicker]="inbound"
                        [comparisonStart]="campaignTwo.value.start"
                        [comparisonEnd]="campaignTwo.value.end"
                        class="fs-12 pb-2 pl-2"
                      >
                        <input matStartDate formControlName="start" />
                        <input
                          matEndDate
                          placeholder="End date"
                          formControlName="end"
                        />
                      </mat-date-range-input>
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="inbound"
                      ></mat-datepicker-toggle>
                      <mat-date-range-picker #inbound></mat-date-range-picker>
                    </mat-form-field>
                    <!-- <mat-form-field appearance="fill" class="border-0 p-0">
                    <mat-date-range-input
                      [rangePicker]="picker"
                      class="fs-12 pb-2 pl-2"
                    >
                      <input matStartDate class="text-align-centre" />
                      <input matEndDate />
                    </mat-date-range-input>

                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                      class="pb-1"
                    ></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field> -->
                  </span>
                </div>
              </div>
              <canvas id="mychart" width="100%" height="70px" #mychart></canvas>
            </div>
          </div>
          <div class="col-md-6">
            <div class="p-2 bg-white rounded h-100">
              <div class="d-flex justify-content-between">
                <div class="d-flex">
                  <div class="mb-0 color-title fs-18 font-bold">
                    Outbound Shipments
                  </div>
                </div>
                <div class="col-md-7">
                  <span class="mat-datePicker">
                    <mat-form-field appearance="fill" class="border-0 p-0">
                      <!-- <mat-label>Second campaign</mat-label> -->
                      <mat-date-range-input
                        [formGroup]="campaignTwo"
                        [rangePicker]="outbound"
                        [comparisonStart]="campaignOne.value.start"
                        [comparisonEnd]="campaignOne.value.end"
                        class="fs-12 pb-2 pl-2"
                      >
                        <input
                          matStartDate
                          placeholder="Start date"
                          formControlName="start"
                          class="text-align-centre"
                        />
                        <input
                          matEndDate
                          placeholder="End date"
                          formControlName="end"
                        />
                      </mat-date-range-input>
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="outbound"
                        class="pb-1"
                      ></mat-datepicker-toggle>
                      <mat-date-range-picker #outbound></mat-date-range-picker>
                    </mat-form-field>
                    <!-- <mat-form-field appearance="fill" class="border-0 p-0">
                    <mat-date-range-input
                      [rangePicker]="pickers"
                      class="fs-12 pb-2 pl-2"
                    >
                      <input matStartDate class="text-align-centre" />
                      <input matEndDate />
                    </mat-date-range-input>

                    <mat-datepicker-toggle
                      matSuffix
                      [for]="pickers"
                      class="pb-1"
                    ></mat-datepicker-toggle>
                    <mat-date-range-picker #pickers></mat-date-range-picker>
                  </mat-form-field> -->
                  </span>
                </div>
              </div>
              <canvas
                id="newchart"
                width="100%"
                height="70px"
                #newchart
              ></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="px-2 py-2 bg-white">
          <div class="border-0 d-flex justify-content-between rounded my-2">
            <span class="fs-20 fw-700 color-faint-gray ml-2"
              >Current Project Status</span
            >

            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex mr-4 align-items-center">
                <span class="fs-14 fw-700 color-faint-gray mr-1">Sort by</span>
                <span class="fs-14 fw-600 px-2 py-1 color-black border rounded"
                  >Sort by
                  <span><img src="../../assets/images/Path 61663.svg" /></span
                ></span>
              </div>
              <div class="d-flex align-items-center">
                <span class="fs-14 fw-700 color-faint-gray ml-2 mr-2"
                  >Filter by</span
                >
                <span class="fs-14 fw-600 px-2 py-1 color-black border rounded"
                  >Filter by
                </span>
              </div>
            </div>
          </div>

          <div
            style="overflow-x: auto"
            class="project-status custom-scroll-bar1"
          >
            <table class="table track_tbl border-0 home-teble">
              <thead id="tableHeader" style="background-color: white">
                <!-- <tr>
                  <th class="text-center border-0 fs-16">
                    Product Development
                  </th>
                  <th class="text-center border-0 fs-16">
                    Product Development
                  </th>
                  <th class="text-center border-0 fs-16">Manufacturing</th>
                  <th class="text-center border-0 fs-16">Shipping</th>
                  <th class="text-center border-0 fs-16">Pre-Launch</th>
                  <th class="text-center border-0 fs-16">Post Launch</th>
                  <th class="text-center border-0 fs-16">Est Comp. Date</th>
                </tr> -->
              </thead>
              <tbody>
                <tr class="py-2" *ngFor="let x of clientProjectList">
                  <td
                    class="bg-gray fs-16 font-bold cursor-pointer"
                    (click)="goToTaskClient(x)"
                  >
                    <span
                      class="d-flex justify-content-center align-content-center"
                      >{{ x.name }}</span
                    >
                  </td>
                  <td class="text-center border-0" *ngFor="let a of x.section">
                    <span
                      *ngIf="a.status != null"
                      [ngStyle]="{
                        color:
                          a.status == 'Completed'
                            ? '#FFF'
                            : a.status == 'Inprogress'
                            ? '#fff'
                            : a.status == 'Future Steps'
                            ? '#646464'
                            : '#000',

                        'background-color':
                          a.status == 'Completed'
                            ? '#00C300'
                            : a.status == 'Inprogress'
                            ? '#FFB72F'
                            : a.status == 'Future Steps'
                            ? '#E0E0E1'
                            : '#E0E0E1'
                      }"
                      class="status-produt position-relative project-status-success font-bold text-white rounded fs-16 px-2 text-truncate"
                    >
                      {{ a.name }}
                    </span>
                  </td>
                  <td class="text-center border-0">
                    <span
                      class="status-produt position-relative project-status-success font-bold text-white rounded fs-16"
                      [ngStyle]="{
                        color:
                          x.project_status == 'Completed' ? '#fff' : '#262627',
                        'background-color':
                          x.project_status == 'Completed'
                            ? '#00C300'
                            : '#ffb72f'
                      }"
                    >
                      {{ x.due_date | date : "MM/dd/yy" }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #summaryStatus>
  <div class="modal-header py-2">
    <h5 class="modal-title pull-left mb-0 fw-700">Summary Of Status</h5>
    <button type="button" class="close pull-right" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-height">
    <table class="table table-bordered fs-18">
      <thead class="position-sticky bg-white">
        <tr class="text-center">
          <th class="w-20" scope="col ">Date</th>
          <th class="w-20" scope="col ">User</th>
          <th scope="col">Summary of Status</th>
        </tr>
      </thead>
      <tbody>
        <tr class="cursor-pointer" *ngFor="let x of summaryStatusData">
          <td>{{ x.createdAt | date : "MM/dd/yy hh:mm a" }}</td>
          <td>{{ x?.user?.name + " " + x?.user?.last_name }}</td>
          <td>{{ x?.summary_of_status }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
