<section class="page_404">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 d-flex justify-content-center">
        <div class="col-sm-10 col-sm-offset-1 text-center">
          <div class="four_zero_four_bg">
            <!-- <h1 class="text-center">404</h1> -->
          </div>

          <div class="contant_box_404">
            <h3 class="text-color-dark fw-700">Page Not Found</h3>

            <p>
              You can search for the home page yo want here or return to the
              home page.
            </p>

            <a
              [routerLink]="['/dashboard']"
              routerLinkActive="router-link-active"
              class="link_404 btn border-orange bg-main text-white mx-auto"
              >Go to Home</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
