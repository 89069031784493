import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthInfoService } from './common-services/auth-info.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private Auth: AuthInfoService) {}

  ngOnInit(): void {
    this.Auth.getUserRole();
    // if (localStorage.getItem('rememberMe') == 'false') {
    //   window.addEventListener('blur', (e) => {
    //     localStorage.clear();
    //   });
    // } else {
    // }

    // if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
    // } else {
    //   if (localStorage.getItem('rememberMe') == 'false' && !document.hidden) {
    //     localStorage.clear();
    //     this.router.navigateByUrl('/auth');
    //   } else {
    //   }
    // }
  }
}
