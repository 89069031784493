import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthInfoService } from 'src/app/common-services/auth-info.service';
import { defineLocale, enGbLocale } from 'ngx-bootstrap/chronos';
import { CommonService } from 'src/app/common-services/common.service';
import { HttpRequestService } from 'src/app/common-services/http-request.service';
import { CONSTANTS } from 'src/app/constants/global-constant';
declare const $: any;

@Component({
  selector: 'app-crud-project',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.scss'],
})
export class ProjectCrudComponent implements OnInit {
  createProjectForm!: NgForm;

  bg = [
    '#44bf1f',
    '#ff3939',
    '#ff9d6c',
    '#2da4ff',
    '#ffe139',
    '#bc6dff',
    '#166ab7',
    '#ff6200',
    '#ab47bc',
    '#00b59e',
    '#9e9e9e',
  ];
  projectName: any = '';
  companyName: any = '';
  Accountmanager: any;
  createClientContact = '';
  projectDescription: any;
  projectDueDate = '';
  clientOverview: any;
  clientOverview_id: any;
  isDate: boolean = false;
  productManager: any;
  leadManager: any;
  technicalValue: any;
  textMessage: any;
  showErrorMessage = false;
  errorMessage = '';
  userId: any;
  clientData: any = [];
  userList: any = [];
  searchAccountValue = '';
  searchProductValue = '';
  searchLeadDesigner = '';
  searchTechnicalValue = '';

  companyNameError: boolean = false;
  accountMangerError: boolean = false;
  technicalMarchandisesError: boolean = false;
  productManagerError: boolean = false;
  leadDesignerError: boolean = false;
  enableSubmit: boolean = false;
  tempId: any;
  templateValue: any;
  templateError: boolean = false;

  @Output()
  public onSuccess = new EventEmitter();
  @Output()
  public onFail = new EventEmitter();

  minDate = new Date();

  @Input()
  initialProject: any = {
    name: '',
    project_description: '',
    due_date: '',
    message_to_team: '',
  };
  @Input()
  projectId: any;

  @Input()
  isEditProject: boolean = false;
  projectValue: any;
  filteredCompanyNameData: any;
  projectSaveName: any;
  TemplateList: any;
  constructor(
    private Auth: AuthInfoService,
    private commonService: CommonService,
    private http: HttpRequestService
  ) {
    this.minDate.setDate(this.minDate.getDate());
    enGbLocale.weekdaysShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    // enGbLocale.week.dow = 0;
    defineLocale('en-gb', enGbLocale);
  }

  ngOnInit(): void {
    this.getAllClients();
    this.getAllusers();
    this.getAllTemplate();
    if (this.isEditProject) {
      this.getProjectData();
    }

    if (this.isEditProject) {
      this.projectSaveName = this.initialProject?.name;
    }
    this.initialProject.due_date = new Date(this.initialProject.due_date);

    if (this.tempId != null || this.tempId != undefined) {
      this.templateValue = this.tempId?.template_name;
    }
  }

  ngAfterViewInit(): void {
    this.Auth.taskNameBehaviour.subscribe((data) => {
      this.projectName = data;
      this.companyName = this.initialProject?.company_name?._id;
      this.Accountmanager = this.initialProject?.account_manager?._id;
      this.leadManager = this.initialProject?.lead_designer?._id;
      this.productManager = this.initialProject?.product_manager?._id;
      this.technicalValue = this.initialProject?.technical_merchandiser?._id;
      this.projectDescription = this.initialProject?.project_description;
      this.textMessage = this.initialProject?.message_to_team;
    });

    window.addEventListener('wheel', (event) => {
      $('.bs-datepicker-container').css('display', 'none');
    });
  }

  //** Create Project Api */
  createProject(x: NgForm) {
    if (
      (!x.valid && this.companyName == null) ||
      this.companyName == '' ||
      this.companyName == undefined
    ) {
      this.companyNameError = true;
      return;
    }

    let rqData: any = {
      name: this.projectName,
      company_name: this.companyName,
      primary_client_contact: this.clientOverview_id,
      project_description:
        this.initialProject?.project_description ?? this.projectDescription,
      // Due_Date: this.initialProject.Due_Date,
      due_date: this.projectDueDate,
      account_manager: this.Accountmanager,
      product_manager: this.productManager,
      lead_designer: this.leadManager,
      technical_merchandiser: this.technicalValue,
      message_to_team: this.initialProject?.message_to_team ?? this.textMessage,
      template: this.tempId?._id ?? this.templateValue,
      color: this.bg[Math.floor(Math.random() * this.bg.length)],
    };

    try {
      this.http
        .request('post', '/employee/project', rqData)
        .subscribe((response: any) => {
          if (response.status == CONSTANTS.API_SUCCESS) {
            this.onSuccess.emit(response.result);
            this.commonService.popUp('Project created successfully.');

            this.Auth.addNode({
              activePath: 'app-list-project',
              data: response?.result,
            });
          } else {
            throw response;
          }
        });
    } catch (error) {}
  }

  //** Edit Project Api */
  editProject(x: NgForm) {
    let rqData: any = {
      name:
        this.projectSaveName == this.initialProject?.name
          ? undefined
          : this.initialProject?.name,
      company_name: this.initialProject?.companyNameClient?.company_name,
      primary_client_contact: this.clientOverview_id,
      project_description: this.initialProject?.project_description,
      due_date: this.initialProject?.due_date,
      account_manager:
        this.Accountmanager ?? this.initialProject?.account_manager?._id,
      product_manager:
        this.productManager ?? this.initialProject?.product_manager?._id,
      lead_designer:
        this.leadManager ?? this.initialProject?.lead_designer?._id,
      technical_merchandiser:
        this.technicalValue ?? this.initialProject?.technical_merchandiser?._id,
      message_to_team: this.initialProject?.message_to_team ?? this.textMessage,
    };

    try {
      this.http
        .request('put', '/employee/project/' + this.projectId, rqData)
        .subscribe((response: any) => {
          this.Auth.addNode({
            activePath: 'app-overview',
            data: response.results,
          });
          this.Auth.addNode({
            activePath: 'app-list-project',
            data: response?.project?.project,
          });
          this.onSuccess.emit(response.result);
          if (response.status == CONSTANTS.API_SUCCESS) {
            this.commonService.popUp('Project updated successfully.');
          } else {
            throw response;
          }
        });
    } catch (error) {
      this.showErrorMessage = false;
    }
    this.onFail.emit();
  }

  //** Country name Dropdown  Functionality */
  companyNameDropdown(event: any, x: any) {
    if (this.templateValue == null || this.templateValue == undefined) {
      this.templateError = true;
      return;
    } else {
      $('.filter-opt1 span').remove();
      let target = event.target;
      let spanTagToPush = $(target).text();
      let spanDataToPush = $(`<span>${spanTagToPush}</span>`);
      $('.filter-opt1').append(spanDataToPush);
      this.companyName = x._id;
      this.clientOverview = x?.pcc?.name + ' ' + x?.pcc?.last_name;
      this.clientOverview_id = x._id;
      this.companyNameError = false;
      this.enableSubmit = true;
    }
  }

  //** Account Manager Dropdown Functionality */
  accountManagerDropdown(event: any, x: any) {
    let target = event.target;
    let spanTagToPush = $(target).text();
    const element = window.document.getElementById('selectAccounter');
    if (element !== null) {
      element.innerText = spanTagToPush;
    }
    this.Accountmanager = x._id;
    // this.accountMangerError = false;
    this.enableSubmit = true;
  }

  //** Product Manager Dropdown Functionality */
  productManagerDropdown(event: any, x: any) {
    let target = event.target;
    let spanTagToPush = $(target).text();
    const element = window.document.getElementById('selectProduct');
    if (element !== null) {
      element.innerText = spanTagToPush;
    }
    this.productManager = x._id;
    // this.productManagerError = false;
    this.enableSubmit = true;
  }

  //** Lead Designer Dropdown Functionality */
  leadDesignerDropdown(event: any, x: any) {
    let target = event.target;
    let spanTagToPush = $(target).text();
    const element = window.document.getElementById('selectDesigner');
    if (element !== null) {
      element.innerText = spanTagToPush;
    }
    this.leadManager = x._id;
    // this.leadDesignerError = false;
    this.enableSubmit = true;
  }

  //** Technical Merchandise Dropdown Functionality */
  technicalMerchandiseDropdown(event: any, x: any) {
    let target = event.target;
    let spanTagToPush = $(target).text();
    const element = window.document.getElementById('selectTechnicalmerch');
    if (element !== null) {
      element.innerText = spanTagToPush;
    }
    this.technicalValue = x._id;
    // this.technicalMarchandisesError = false;
    this.enableSubmit = true;
  }

  //** All Client Api get method Functionality */
  getAllClients() {
    try {
      this.http
        .request('get', '/employee/client/getRoleClientFromUser', null)
        .subscribe((response: any) => {
          this.clientData = response.data;

          this.clientData = this.clientData.sort((a: any, b: any) => {
            var nameA = a?.company_name?.toLowerCase(),
              nameB = b?.company_name?.toLowerCase();
            if (nameA < nameB) return -1;
            return 0;
          });

          this.Auth.set('client', response.data);
        });
    } catch (error) {}
  }

  //** All users Api get method functionality */
  getAllusers() {
    try {
      this.http
        .request('get', '/employee/user/getEmployees', null)
        .subscribe((response: any) => {
          this.userList = response.result;
          this.userList = this.userList.sort((a, b) =>
            a.name > b.name ? 1 : -1
          );

          this.Auth.set('users', this.userList);
        });
    } catch (error) {}
  }

  //** Project Data get api Functionality */
  getProjectData() {
    try {
      this.http
        .request(
          'get',
          '/employee/project/getProjectOverview/' + this.projectId,
          null
        )
        .subscribe((response: any) => {
          this.clientOverview =
            response?.client?.companyNameClient?.pcc?.name +
            ' ' +
            response?.client?.companyNameClient?.pcc?.last_name;
          this.clientOverview_id = response?.client?._id;
        });
    } catch (error) {}
  }

  //** Reset Form */
  resetForm() {
    this.onFail.emit();
  }

  //** Account Manager Search Functionality */
  searchAccountManager(event: any) {
    this.userList = this.Auth.get('users');
    if (event != null) {
      const search = this.Auth.get('users').filter((e: any) =>
        e?.name?.toLowerCase().includes(event.toLowerCase())
      );
      this.userList = search;
    }
  }

  //** Product Manager Search Functionality */
  searchProductManager(event: any) {
    this.userList = this.Auth.get('users');
    if (event != null) {
      const search = this.Auth.get('users').filter((e: any) =>
        e?.name?.toLowerCase().includes(event.toLowerCase())
      );
      this.userList = search;
    }
  }

  //** Lead Designers Search Functionality */
  searchLeadDesigners(event: any) {
    this.userList = this.Auth.get('users');
    if (event != null) {
      const search = this.Auth.get('users').filter((e: any) =>
        e?.name?.toLowerCase().includes(event.toLowerCase())
      );
      this.userList = search;
    }
  }

  //** Technical Merchandiser Search Functionality */
  searchTechnicalMerchandiser(event: any) {
    this.userList = this.Auth.get('users');
    if (event != null) {
      const search = this.Auth.get('users').filter((e: any) =>
        e?.name?.toLowerCase().includes(event.toLowerCase())
      );
      this.userList = search;
    }
  }

  //** Save Button Enable Functionality */
  enableSave() {
    this.enableSubmit = true;
  }

  //** Get all templates Functionality   */
  getAllTemplate() {
    try {
      this.http
        .request('get', '/employee/template/getAllTemplates', null)
        .subscribe((res: any) => {
          this.TemplateList = res.result.filter(
            (x: any) => x.category == 'Final'
          );

          this.TemplateList = this.TemplateList.sort((a: any, b: any) => {
            var nameA = a?.template_name?.toLowerCase(),
              nameB = b?.template_name?.toLowerCase();
            if (nameA < nameB) return -1;
            return 0;
          });
        });
    } catch (error) {}
  }

  //** Template Dropdown Functionality */
  templateDropDown(event: any, x: any) {
    let target = event.target;
    let spanTagToPush = $(target).text();
    const element = window.document.getElementById('selectTemplate');
    if (element !== null) {
      element.innerText = spanTagToPush;
    }
    this.tempId = this.templateValue = x._id;
    this.enableSubmit = true;
    if (this.templateValue != null || this.templateValue != undefined) {
      this.templateError = false;
    }
  }
}
