import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from 'src/app/common-modules/shared.module';
import { HomeComponent } from './home/home.component';
import { LeftMenuComponent } from '../left-menu/left-menu.component';
import { PipeModuleModule } from 'src/app/common-pipes/pipe-module.module';
// importing material neccesary module
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [DashboardComponent, HomeComponent, LeftMenuComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    PipeModuleModule,
    SharedModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    NgSelectModule,
    MatTooltipModule,
  ],
  providers: [MatDatepickerModule],
})
export class DashboardModule {}
