import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// initial component loaded list..
import { AppComponent } from './app.component';
import { FooterComponent } from './common-components/footer/footer.component';
import { ResetPasswordComponent } from './common-components/reset-password/reset-password.component';
// import { HeaderComponent } from './common-components/header/header.component';
import { RouterModule } from '@angular/router';
import { HttpErrorInterceptorInterceptor } from './common-services/http-error-interceptor.interceptor';
// extra module for initial compoents
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ModalComponent } from './common-components/modal/modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PipeModuleModule } from './common-pipes/pipe-module.module';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { MentionModule } from 'angular-mentions';
import { ForgetPasswordComponent } from './common-components/forget-password/forget-password.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    ResetPasswordComponent,
    ModalComponent,
    ForgetPasswordComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DragDropModule,
    PipeModuleModule,
    DashboardModule,
    MentionModule,
  ],

  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
