import { HttpBackend, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIResponse } from '../models/api-model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpBackendRequestService {
  constructor(private httpBackend: HttpBackend) {}
  baseURL = environment.DEV.BASE_URL;

  headerData = new HttpHeaders({
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  });

  // function for CRUD
  request(
    requestType: string,
    requestURL: string,
    requestBody: any
  ): Observable<APIResponse> {
    // for get request..
    if (requestType === 'get') {
      return this.httpBackend.handle(
        new HttpRequest('GET', this.baseURL + requestURL, {
          headers: this.headerData,
        })
      );
    }

    // for post request(adding)..
    if (requestType === 'post') {
      return this.httpBackend.handle(
        new HttpRequest('POST', this.baseURL + requestURL, requestBody, {
          headers: this.headerData,
        })
      );
    }

    // for put request(updating with all required values)..
    if (requestType === 'put') {
      return this.httpBackend.handle(
        new HttpRequest('PUT', this.baseURL + requestURL, requestBody, {
          headers: this.headerData,
        })
      );
    }

    // for patch request(updating with specific values)..
    if (requestType === 'patch') {
      return this.httpBackend.handle(
        new HttpRequest('PATCH', this.baseURL + requestURL, requestBody, {
          headers: this.headerData,
        })
      );
    }

    // for delete request..
    if (requestType === 'delete') {
      return this.httpBackend.handle(
        new HttpRequest('DELETE', this.baseURL + requestURL, {
          headers: this.headerData,
        })
      );
    }
  }
}
