import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError, retry, finalize } from 'rxjs/operators';
import { CommonService } from './common.service';
import { AuthInfoService } from './auth-info.service';
import { Router } from '@angular/router';
declare const $: any;

@Injectable()
export class HttpErrorInterceptorInterceptor implements HttpInterceptor {
  constructor(
    private commonService: CommonService,
    private Auth: AuthInfoService,
    private routes: Router
  ) {}
  private totalRequests = 0;
  Errorstatus = true;

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.totalRequests++;
    $('#loader').show();
    const reqWithAuth = request.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    return next.handle(reqWithAuth).pipe(
      // retry function for retry api automatically
      // retry(1),
      finalize(() => {
        // all request hit then spinner hide..
        this.totalRequests--;
        if (this.totalRequests === 0) {
          $('#loader').hide();
        }
      }),

      // API error handling...
      catchError((error: HttpErrorResponse) => {
        if (error.error) {
          // if (error.error.error.statusCode == '404') {
          //   this.commonService.popUp('API Not found !!!');
          // } else if (error.error.error.statusCode == '401') {
          //   this.commonService.popUp('User is not Authorized !!!');
          //   localStorage.clear();
          // } else if (error.error.error.statusCode == '403') {
          //   this.commonService.popUp('Access forbidden !!!');
          // } else if (error.error.error.statusCode == '500') {
          //   if (this.Errorstatus) {
          //     this.commonService.popUp('Something went wrong !!!');
          //     this.Errorstatus = false;
          //   }
          //   if (error.error.error.message == 'invalid token') {
          //     localStorage.clear();
          //     this.routes.navigateByUrl('/auth');
          //   }
          // } else if (error.error.error.statusCode == '501') {
          //   this.commonService.popUp('Unable to get data');
          // } else if (error.error.statusCode == '401') {
          //   this.commonService.popUp(
          //     'You are not logged in! Please log in to get access.'
          //   );
          // } else {
          //   this.commonService.popUp(error.error.message);
          // }

          this.commonService.popUp(error.error.message);
          if (error.error.error.statusCode == '500') {
            if (
              error.error.error.message == 'invalid token' ||
              error.error.error.message == 'jwt expired' ||
              error.error.error.message == 'invalid signature'
            ) {
              localStorage.clear();
              this.routes.navigateByUrl('/auth');
            }
          }
          if (error.error.error.statusCode == '401') {
            if (error.statusText == 'Unauthorized') {
              localStorage.clear();
              this.routes.navigateByUrl('/auth');
            }
          }
        } else {
          this.commonService.popUp('Something went wrong');
        }
        return throwError(error);
      })
    );
  }
}
