<div id="loader">
  <div class="loader loader1"></div>
  <div class="loader loader2"></div>
  <div class="loader loader3"></div>
  <div class="loader loader4"></div>
  <div class="loader loader5"></div>
</div>
<router-outlet></router-outlet>

<button
  class="feedback-btn"
  data-tf-popup="Td4LKDvJ"
  data-tf-opacity="100"
  data-tf-size="100"
  data-tf-iframe-props="title=Beta Product Feedback Survey"
  data-tf-transitive-search-params
  data-tf-medium="snippet"
>
  Feedback
</button>
