import { Component, OnInit, Type } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthInfoService } from 'src/app/common-services/auth-info.service';
import { CommonService } from 'src/app/common-services/common.service';
import { HttpRequestService } from 'src/app/common-services/http-request.service';
import { DatePipe } from '@angular/common';
import { ProjectCrudComponent } from '../project/crud/crud.component';
import { NgForm } from '@angular/forms';
import { ProjectModule } from '../project/project.module';
import { ClientModule } from '../profile/client/client.module';
import { Permission } from 'src/app/models/permission';
import { Roles } from 'src/app/models/roles';
import { HttpBackendRequestService } from 'src/app/common-services/http-backend-request';
import { HttpResponse } from '@angular/common/http';
declare const $: any;
@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit {
  userDetail: any;
  userName = '';
  userImage: any;
  profile: any = false;
  action: any = false;
  admin: any = false;
  projects: any = false;
  isClient: boolean = false;
  bsEditorModalRef!: BsModalRef;
  isForDashboard: boolean = true;
  inboxItem: any = [];
  clientData: any;
  loadProject: any = [];
  visible: boolean = true;
  clientInfo: any;
  displayImage: any;
  url: any;
  errorMessage = '';
  firstName = '';
  lastName = '';
  emailAddress = '';
  contact = '';
  clientName = '';
  roleEmployee: boolean = false;
  projectLazy: Promise<Type<ProjectModule>>;
  clientLazy: Promise<Type<ClientModule>>;
  clientID: any;
  role: Roles;

  permissions: Permission = null;
  userData = JSON.parse(localStorage.getItem('userData'));

  projectRoutes: string;

  constructor(
    public Auth: AuthInfoService,
    private http: HttpRequestService,
    private modalService: BsModalService,
    private router: Router,
    public datepipe: DatePipe,
    private commonService: CommonService,
    private httpBackend: HttpBackendRequestService
  ) {}

  date = new Date();
  latest_date = this.datepipe.transform(this.date, 'yyyy/MM/dd');
  yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  yes_date = this.datepipe.transform(this.yesterday, 'yyyy/MM/dd');

  async loadProjectModule() {
    //**  use this code */
    //** Also check in HTML */
    if (!this.projectLazy) {
      this.projectLazy = import('../project/project.module').then(
        (m) => m.ProjectModule
      );
    }
  }

  ngOnInit(): void {
    this.inboxInitilize();
    this.permissions = this.userData['groupId'];
    this.role = this.userData;

    if (localStorage.getItem('userData')) {
      this.userDetail = JSON.parse(localStorage.getItem('userData') ?? '');
      this.displayImage = this.userDetail.profile_image;
    }

    this.userName = this.userDetail.name + ' ' + this.userDetail?.last_name;

    this.userImage =
      this.userDetail?.profile_image ??
      '../../assets/images/man-avatar-profile-vector-21372076.jpg';
    this.getInbox();

    if (this.Auth.getUserRole()) {
      this.getAllClients();
    } else if (this.Auth.roleList.isClient || this.Auth.roleList.isUser) {
      this.clientID = this.userDetail.companyNameClient._id
        ? this.userDetail.companyNameClient._id
        : this.userDetail.companyNameClient;
      this.clientDetails(this.clientID);
    } else if (this.Auth.roleList.isUser) {
      this.clientID = this.userDetail.companyNameClient._id
        ? this.userDetail.companyNameClient._id
        : this.userDetail.companyNameClient;
    }
  }

  //** close slider function */
  closeAllSlider() {
    this.Auth.closeSlider({});
  }

  //** left menu toggled project,action and profile tab will be disabled  */
  itemClicked() {
    this.profile = false;
    this.action = false;
    this.admin = false;
    this.projects = false;
    $('#collapseOne').removeClass('show');
    $('#collapseTwo').removeClass('show');
    $('#collapseThree').removeClass('show');
    $('#collapseFour').removeClass('show');
    this.closeAllSlider();

    this.Auth.addNode({
      activePath: 'read-notification',
      isNotification: true,
    });
  }

  inboxInitilize() {
    this.commonService.projectRoute.subscribe((res) => {
      this.projectRoutes = res;
    });
    this.Auth.node$.subscribe((e: any) => {
      if (e != null) {
        if (e?.activePath == 'app-left-menu') {
          this.userDetail = e?.data;
          this.userName =
            this.userDetail?.name + ' ' + this.userDetail?.last_name;
          this.userImage = this.userDetail?.profile_image;

          if (this.role?.role == 'user') {
            this.clientName =
              this.userDetail.name + ' ' + this.userDetail.last_name;
            this.displayImage = e.data.profile_image;
          }
        }
        if (e?.activePath == 'app-general') {
          this.displayImage = e.data.profile_image;
        }
        if (e?.activePath == 'app-list-client') {
          this.getAllClients();
        }

        if (e?.activePath == 'app-list-project') {
          this.getAllClients();
        }

        if (e.activePath == 'read-notification' && e.isNotification) {
          this.getReadInbox();
          this.showNotification();
        }
      }
    });
  }

  //** clicked on client and supllier to display the data  */
  profileClicked() {
    this.profile = true;
    this.action = false;
    this.admin = false;
    this.projects = false;
    $('#collapseOne').removeClass('show');
    $('#collapseTwo').removeClass('show');
    $('#collapseFour').removeClass('show');
    this.closeAllSlider();

    this.Auth.addNode({
      activePath: 'read-notification',
      isNotification: true,
    });
  }

  //** inbox and myTask to dislay their data */
  actionClicked() {
    this.action = true;
    this.admin = false;
    this.profile = false;
    this.projects = false;
    this.closeAllSlider();
    $('#projectsSubmenu').removeClass('show');
    $('#profilesSubmenu').removeClass('show');
    $('#adminSubmenu').removeClass('show');
    this.Auth.addNode({
      activePath: 'read-notification',
      isNotification: true,
    });
  }

  adminClicked() {
    this.admin = true;
    this.action = false;
    this.profile = false;
    this.projects = false;
    this.closeAllSlider();
    $('#actionItemsSubmenu').removeClass('show');
    $('#projectsSubmenu').removeClass('show');
    $('#profilesSubmenu').removeClass('show');

    this.Auth.addNode({
      activePath: 'read-notification',
      isNotification: true,
    });
  }

  //** Projects collapse for displaying list of projects */
  projectsClicked(rlaclient: any, company_Name: string) {
    this.projects = true;
    this.action = false;
    this.admin = false;
    this.profile = false;

    if (rlaclient.isActive === false) {
      this.Auth.set('projectId', null);
      this.Auth.addNode({
        activePath: 'tasks',
        data: null,
      });
    }

    this.Auth.set('projectNavigate', null);

    this.closeAllSlider();
    $('#actionItemsSubmenu').removeClass('show');
    $('#profilesSubmenu').removeClass('show');
    $('#adminSubmenu').removeClass('show');
    localStorage.setItem('companyName', company_Name);
    localStorage.removeItem('projectSelected');
  }
  collapseSide() {
    this.isForDashboard = !this.isForDashboard;
    $('#sidebar,#routeContent').hasClass('sidebar-collapsed')
      ? $('#sidebar,#routeContent,.hamburger,#actionItemsSubmenu').removeClass(
          'sidebar-collapsed'
        )
      : $('#sidebar,#routeContent,.hamburger,#actionItemsSubmenu').addClass(
          'sidebar-collapsed'
        );
  }

  //** get All clients api */
  getAllClients() {
    try {
      this.http
        .request('get', '/employee/client/getRoleClientFromUser', null)
        .subscribe((response: any) => {
          this.clientData = response.data;

          this.clientData = this.clientData.filter((x: any) => {
            if (x.project.length > 0) {
              return x;
            }
          });

          this.clientData = this.clientData.sort((a: any, b: any) => {
            var nameA = a?.company_name?.toLowerCase(),
              nameB = b?.company_name?.toLowerCase();
            if (nameA < nameB) return -1;
            return 0;
          });

          this.Auth.set('client', response.data);
        });
    } catch (error) {}
  }

  //** profile toggle for displaying client and suppliers */
  profileToggleCollapsed() {
    this.profile = !this.profile;
    this.action = false;
    this.admin = false;
    this.projects = false;
  }
  //** clicked on Profile Icons to toggle to profile */
  profileIcons() {
    $('#collapseThree').collapse('toggle');
  }

  //** project toggle for displaying list of projects */
  projectToggleCollapsed() {
    this.loadProjectModule();
    this.projects = !this.projects;

    this.action = false;
    this.admin = false;
    this.profile = false;
  }
  //** project toggle for client side */
  projectToggleCollapsed1() {
    this.loadProjectModule();
    this.projects = true;
    this.action = false;
    this.admin = false;
    this.profile = false;
  }

  //** clicked on project Icons to toggle the project tab */
  projectIcons() {
    $('#collapseTwo').collapse('toggle');
  }

  //**  action items toggle for displaying inbox and mytask */
  actionItemsToggleCollapsed() {
    this.action = !this.action;
    this.projects = false;
    this.profile = false;
    this.admin = false;
  }

  adminItemsToggleCollapsed() {
    this.admin = !this.admin;
    this.action = false;
    this.projects = false;
    this.profile = false;
  }

  //**  action items icon to toggle action item tab */
  actionItems() {
    $('#collapseOne').collapse('toggle');
    // $("#actionItems").attr("aria-expanded", "false");
  }

  adminItems() {
    $('#collapseFour').collapse('toggle');
    // $("#actionItems").attr("aria-expanded", "false");
  }

  //**Create new project modal pop up */
  createNewProject() {
    let option = {};
    this.bsEditorModalRef = this.modalService.show(ProjectCrudComponent, {
      initialState: option,
      class: 'modal-dialog modal-lg',
      ignoreBackdropClick: true,
    });

    this.bsEditorModalRef.content.onFail.subscribe((x: any) => {
      this.bsEditorModalRef.hide();
    });

    this.bsEditorModalRef.content.onSuccess.subscribe((x: any) => {
      this.bsEditorModalRef.hide();

      this.loadProject.push(x);
      this.Auth.set('projects', this.loadProject);

      this.Auth.addNode({
        activePath: 'app-list',
        data: x,
      });
    });
  }

  //** add new client slider */
  addNewClient(event: any) {
    // this.loadClientModule(); // need to change
    event.stopPropagation();
    this.Auth.addNode({ activePath: 'app-crud-client' });
    this.Auth.openSlider({
      componentName: 'app-crud-client',
      headerName: 'Add New Customer',
      data: {
        isClient: true,
      },
    });
  }

  //** add New suppliers slider */
  addNewSupplier(event: any) {
    event.stopPropagation();
    this.Auth.addNode({ activePath: 'app-crud-supplier' });
    this.Auth.openSlider({
      componentName: 'app-crud-supplier',
      headerName: 'Add New Supplier',
      data: {
        isClient: true,
      },
    });
  }

  //** Get inbox api for notification */
  getInbox() {
    this.http
      .request('get', '/employee/comment/inbox', null)
      .subscribe((e: any) => {
        this.inboxItem = e.results;
        this.inboxItem.reverse();
      });
  }

  //** logout functionality */
  logout() {
    setTimeout(() => {
      localStorage.clear();
      this.router.navigateByUrl('/auth');
    }, 500);
  }

  // Clients Functionality
  initial(x: any) {
    this.firstName = x.name;
    this.lastName = x.last_name;
    this.emailAddress = x.email;
    this.contact = x.phone;
    this.url = x.profile_image;
  }

  //** Getting client Data */
  clientDetails(id: any) {
    try {
      this.http
        .request('get', '/client/client/getClient/' + id, null)
        .subscribe((response: any) => {
          this.clientInfo = response.results;
          this.clientName =
            this.clientInfo?.companyNameClient.pcc.name +
            ' ' +
            this.clientInfo?.companyNameClient.pcc?.last_name;
          this.displayImage = this.clientInfo.companyNameClient.profile_image;
          this.initial(response.results);
        });
    } catch (error) {}
  }

  //** route for the project highlight */
  routeFun(val) {
    this.commonService.projectRoute.next(val);
  }

  //** Navigate to project as task in the routes */
  navigateToProject(data: any) {
    this.commonService.projectRoute.next('/task');
    this.router.navigateByUrl('/project/' + data._id + '/task');

    this.Auth.addNode({
      activePath: 'read-notification',
      isNotification: true,
    });
  }

  /* Read notification API */
  readNotification() {
    this.Auth.addNode({
      activePath: 'read-notification',
      isReadNotification: true,
    });

    this.router.navigateByUrl('/notification/inbox/inbox-message');
  }

  /* Show notification red dot based on isRead data */
  showNotification() {
    return this.inboxItem.some((message) => message.isRead === false);
  }

  /* Read notification on bell button click without loader */
  readNotify() {
    try {
      this.httpBackend
        .request('patch', '/employee/comment/readNotification', null)
        .subscribe((response: any) => {
          if (response instanceof HttpResponse) {
            let responseData = response.body.inbox;
            let notification = this.inboxItem.map((item: any) => {
              const matchingResponseItem = responseData.find(
                (responseItem: any) => responseItem._id === item._id
              );
              if (matchingResponseItem) {
                return { ...item, isRead: matchingResponseItem.isRead };
              }
              return item;
            });

            this.Auth.addNode({
              activePath: 'read-notification',
              isNotification: true,
              data: notification,
            });
          }
        });
    } catch (error) {}
  }

  /* Get inbox data without loader */
  getReadInbox() {
    try {
      this.httpBackend
        .request('get', '/employee/comment/inbox', null)
        .subscribe((e: any) => {
          if (e instanceof HttpResponse) {
            this.inboxItem = e.body.results;
            this.inboxItem.reverse();
          }
        });
    } catch (error) {}
  }
}
