import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SLIDERWIDTH } from '../constants/global-constant';
import { Permission, PermissionList, rolesList } from '../models/permission';

@Injectable({
  providedIn: 'root',
})
export class AuthInfoService {
  private data: any = [];
  authToken: any;
  public modalData: any = new BehaviorSubject(null);
  public isForTaskCommentComponent: any = new BehaviorSubject(null);
  public taskNameBehaviour = new BehaviorSubject(null);
  private userNameSliderInfo = new BehaviorSubject(null);
  public newTask = new BehaviorSubject(null);

  //** Role List */
  public roleList: rolesList = {
    isAdmin: false,
    isEmployee: false,
    isClient: false,
    isUser: false,
  };
  //** Permission list */
  public permissionList: PermissionList = {
    dashboard: true,
    action_items: true,
    project: {
      list_project: true,
      create_project: false,
      update_project: false,
      delete_project: false,
      update_task: false,
      delete_task: false,
      create_task: false,
      client_Services: false,
    },
    profile: {
      client: {
        list_client: true,
        create_client: false,
        update_client: false,
        delete_client: false,
      },
      supplier: {
        list_supplier: true,
        create_supplier: false,
        update_supplier: false,
        delete_supplier: false,
      },
    },
    setting: true,
    admin_setting: false,
  };

  // userPermission: any = {
  //   admin: false,
  //   editor: false,
  //   customer: false,
  //   supplier: false,
  //   user: false,
  // };

  constructor(private route: Router) {}

  userData = JSON.parse(localStorage.getItem('userData'));
  // permissions: Permission = this.userData['groupId'];

  // set auth token..
  set setAuthToken(val: any) {
    this.authToken = val;
  }
  // get auth token..
  get getAuthToken() {
    return this.authToken;
  }

  // get user info..
  get getuserInfo() {
    return JSON.parse(localStorage.getItem('userData'));
  }
  // set user info..
  set setuserInfo(val) {
    localStorage.setItem('userData', JSON.stringify(val));
  }

  // if user is already logged in then it will redirect to dashboard page
  loggedUser() {
    if (localStorage.getItem('token')) {
      this.route.navigateByUrl('/dashboard');
    } else {
      this.route.navigateByUrl('/auth');
    }
  }

  //** rxjs observable */
  node = new BehaviorSubject(null);
  get node$() {
    return this.node.asObservable();
  }

  async addNode(data: any) {
    this.node.next(data);
  }

  sliderNode = new BehaviorSubject(null);
  get sliderNode$() {
    return this.sliderNode.asObservable();
  }

  //** open slider */
  openSlider(data: any) {
    data.isShow = true;
    data.width = SLIDERWIDTH.OPEN;
    this.sliderNode.next(data);
  }

  //** Close slider */
  closeSlider(data: any) {
    data.isShow = false;
    if (data?.componentName == null) {
      data.componentName = '';
    }
    data.width = SLIDERWIDTH.CLOSE;
    this.sliderNode.next(data);
  }

  //** Get role for showing relevant data of user */
  getUserRole() {
    if (localStorage.getItem('role') == 'admin') {
      this.roleList.isAdmin = true;
      this.roleList.isEmployee = false;
      this.roleList.isClient = false;
      this.roleList.isUser = false;
      this.setUsersPermissions('admin');
    } else if (localStorage.getItem('role') == 'employee') {
      this.roleList.isAdmin = false;
      this.roleList.isEmployee = true;
      this.roleList.isClient = false;
      this.roleList.isUser = false;
      this.setUsersPermissions('employee');
    } else if (localStorage.getItem('role') == 'client') {
      this.roleList.isAdmin = false;
      this.roleList.isEmployee = false;
      this.roleList.isClient = true;
      this.roleList.isUser = false;
      this.setUsersPermissions('client');
    } else if (localStorage.getItem('role') == 'user') {
      this.roleList.isAdmin = false;
      this.roleList.isEmployee = false;
      this.roleList.isClient = false;
      this.roleList.isUser = true;
      this.setUsersPermissions('user');
    }
    if (
      (localStorage.getItem('role') == 'employee' ||
        localStorage.getItem('role') == 'admin') &&
      JSON.parse(localStorage.getItem('userData'))['groupId']['name'] ==
        'client services'
    ) {
    }

    //** og code */
    if (
      localStorage.getItem('role') == 'employee' ||
      localStorage.getItem('role') == 'admin'
    ) {
      return true;
    } else {
      return false;
    }
  }

  // set Permissions according users
  setUsersPermissions(type: string) {
    if (
      localStorage.getItem('role') == 'employee' &&
      JSON.parse(localStorage.getItem('userData'))['groupId']['name'] ==
        'Client Services'
    ) {
      this.permissionList = {
        dashboard: true,
        action_items: true,
        project: {
          list_project: true,
          create_project: false,
          update_project: true,
          delete_project: true,
          update_task: true,
          delete_task: true,
          create_task: true,
          client_Services: true,
        },
        profile: {
          client: {
            list_client: true,
            create_client: true,
            update_client: true,
            delete_client: true,
          },
          supplier: {
            list_supplier: true,
            create_supplier: true,
            update_supplier: true,
            delete_supplier: true,
          },
        },
        setting: true,
        admin_setting: false,
      };
    } else if (
      localStorage.getItem('role') == 'admin' &&
      JSON.parse(localStorage.getItem('userData'))['groupId']['name'] ==
        'Client Services'
    ) {
      this.permissionList = {
        dashboard: true,
        action_items: true,
        project: {
          list_project: true,
          create_project: false,
          update_project: true,
          delete_project: true,
          update_task: true,
          delete_task: true,
          create_task: true,
          client_Services: true,
        },
        profile: {
          client: {
            list_client: true,
            create_client: true,
            update_client: true,
            delete_client: true,
          },
          supplier: {
            list_supplier: true,
            create_supplier: true,
            update_supplier: true,
            delete_supplier: true,
          },
        },
        setting: true,
        admin_setting: true,
      };
    } else if (type == 'admin') {
      this.permissionList = {
        dashboard: true,
        action_items: true,
        project: {
          list_project: true,
          create_project: false,
          update_project: true,
          delete_project: true,
          update_task: true,
          delete_task: true,
          create_task: true,
          client_Services: false,
        },
        profile: {
          client: {
            list_client: true,
            create_client: true,
            update_client: true,
            delete_client: true,
          },
          supplier: {
            list_supplier: true,
            create_supplier: true,
            update_supplier: true,
            delete_supplier: true,
          },
        },
        setting: true,
        admin_setting: true,
      };
    } else if (type == 'employee') {
      this.permissionList = {
        dashboard: true,
        action_items: true,
        project: {
          list_project: true,
          create_project: false,
          update_project: true,
          delete_project: true,
          update_task: true,
          delete_task: true,
          create_task: true,
          client_Services: false,
        },
        profile: {
          client: {
            list_client: true,
            create_client: true,
            update_client: true,
            delete_client: true,
          },
          supplier: {
            list_supplier: true,
            create_supplier: true,
            update_supplier: true,
            delete_supplier: true,
          },
        },
        setting: true,
        admin_setting: false,
      };
    } else if (type == 'client') {
      this.permissionList = {
        dashboard: true,
        action_items: true,
        project: {
          list_project: true,
          create_project: false,
          update_project: false,
          delete_project: false,
          update_task: false,
          delete_task: false,
          create_task: false,
          client_Services: false,
        },
        profile: {
          client: {
            list_client: false,
            create_client: false,
            update_client: false,
            delete_client: false,
          },
          supplier: {
            list_supplier: false,
            create_supplier: false,
            update_supplier: false,
            delete_supplier: false,
          },
        },
        setting: true,
        admin_setting: true,
      };
    } else if (type == 'user') {
      this.permissionList = {
        dashboard: true,
        action_items: true,
        project: {
          list_project: true,
          create_project: false,
          update_project: false,
          delete_project: false,
          update_task: false,
          delete_task: false,
          create_task: false,
          client_Services: false,
        },
        profile: {
          client: {
            list_client: false,
            create_client: false,
            update_client: false,
            delete_client: false,
          },
          supplier: {
            list_supplier: false,
            create_supplier: false,
            update_supplier: false,
            delete_supplier: false,
          },
        },
        setting: true,
        admin_setting: false,
      };
    }
  }

  //** checking permission as per log-in role */
  checkPermssions(type: string, type2: string) {
    if (type2) {
      return this.permissionList[type][type2] == true;
    } else {
      return this.permissionList[type] == true;
    }
  }

  // get data
  get(key: any) {
    return this.data[key];
  }

  // set data
  set(key: any, data: any) {
    this.data[key] = data;
  }

  editUserNameSlider(data: any) {
    this.userNameSliderInfo.next(data);
  }

  editUserNameSliderInfo() {
    return this.userNameSliderInfo;
  }
}
