import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaysAgoPipe } from './days-ago.pipe';
import { NumberPipe } from './number.pipe';
import { PhonePipe } from './phone.pipe';

const pipesList = [DaysAgoPipe, NumberPipe, PhonePipe];
@NgModule({
  declarations: [pipesList],
  exports: [pipesList],
  imports: [CommonModule],
})
export class PipeModuleModule {}
