import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopUpModalComponent } from '../common-components/pop-up-modal/pop-up-modal.component';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { SLIDERWIDTH } from '../constants/global-constant';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  modalRef: BsModalRef;
  sliderNode = new BehaviorSubject(null);
  errorCountArry = [];

  projectRoute = new BehaviorSubject('/task');

  constructor(private toaster: ToastrService, private modal: BsModalService) {}

  //** successfull toaster message..*/
  sucsessToaster(content: string) {
    this.toaster.success(content);
  }

  //** error toaster message */
  errorToaster(content: string) {
    this.toaster.error(content);
  }

  //** error pop-up message */
  popUp(content: string) {
    const initialState = {
      statusMessage: content,
    };

    this.modalRef = this.modal.show(PopUpModalComponent, {
      initialState,
      backdrop: 'static',
      ignoreBackdropClick: false,
      class: 'modal-md modal-dialog-centered',
    });

    this.storeData(this.modalRef);
  }

  //** Storing error message array */
  storeData(data) {
    this.errorCountArry.push(data);
  }

  //** Close pop-up */
  closePopUp(val) {
    let indexNum: number;
    this.errorCountArry.map((item, index) => {
      if (item.content.statusMessage == val) {
        indexNum = index;
      }
    });
    this.errorCountArry[indexNum].hide();
  }

  //** Get slider data */
  get sliderNode$() {
    return this.sliderNode.asObservable();
  }
}
