import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthInfoService } from 'src/app/common-services/auth-info.service';
import { HttpRequestService } from 'src/app/common-services/http-request.service';
declare const $: any;
import { FormGroup, FormControl } from '@angular/forms';
// importing chart js
// importing chart js
import { Chart } from 'chart.js';
import { Roles } from 'src/app/models/roles';
import { DatePipe } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  userId: any;
  filterId: any;
  showErrorMessage = false;
  search: any = '';
  searchProject: any = '';
  dueDate: any = '';
  Status: any = '';
  blockage: any = '';
  project_description: any = '';
  message_to_team: any = '';
  nextSteps: any = '';
  searchAll: any = '';
  projectList: any = [];
  responsibility: any = [];
  clientname: any = [];
  suppliername: any = [];
  data: {
    priority: string;
    status: string;
    stage: string;
    responsibility: string;
    clientName: string;
    supplierName: string;
  };
  //  filter object for filtering the table data..
  filterCategories: any = {
    priority: '',
    currentStatus: '',
    projectStage: '',
    responsibility: '',
    clinetName: '',
    supplierName: '',
    overall_project_status: '',
  };

  project_priorities = ['Low', 'Medium', 'High'];
  projectCurrentStatus = ['On Time', 'Late', 'At Risk'];

  multiFilter = {
    filterPriorities: [],
    overAllStatus: [],
    currentStatus: [],
    accountManager: [],
    companyName: [],
    supplierName: [],
  };

  overall_project_status = [
    'Ideation',
    'Active',
    'On Hold',
    'Completed',
    'Archive',
  ];

  bsConfig = {
    dateInputFormat: 'MM-DD-YYYY',
    showWeekNumbers: false,
    showClearButton: false,
    adaptivePosition: true,
  };

  supplierCategory: any;
  supplierSubCategory: any;

  modalRef!: BsModalRef;
  @ViewChild('summaryStatus') summaryStatus!: TemplateRef<any>;
  summaryStatusData: any;

  // declartion of variable for chart
  canvas: any;
  newcanvas: any;
  ctx: any;
  rtx: any;
  @ViewChild('mychart') mychart: any;
  @ViewChild('newchart') newchart: any;
  userDetail: any;
  projectId: any;

  // declare varibales for date picker
  campaignOne: FormGroup;
  campaignTwo: FormGroup;
  userData: any;
  role: Roles;
  clientData: any;

  projectFilterData = {
    project: '',
    release_date: '',
    priority: '',
    overall_project_status: '',
    current_status: '',
    responsibility: '',
    customer_name: '',
    supplier_name: '',
    SupplierCategory: '',
    SupplierSubCategory: '',
    summaryStatus: '',
    description: '',
    messageToTeam: '',
  };

  minDate = new Date();

  constructor(
    public Auth: AuthInfoService,
    private router: Router,
    private http: HttpRequestService,
    private datePipe: DatePipe,
    private modal: BsModalService
  ) {
    // date picker
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    this.campaignOne = new FormGroup({
      start: new FormControl(new Date(year, month, 0)),
      end: new FormControl(new Date(year, month, 0)),
    });

    this.campaignTwo = new FormGroup({
      start: new FormControl(new Date(year, month, 0)),
      end: new FormControl(new Date(year, month, 0)),
    });

    this.Auth.node$.subscribe((e: any) => {
      if (e != null) {
        if (e?.activePath == 'app-list') {
          this.getProjectFromId();
          // this.range($event);
        }
      }
    });
  }

  ngOnInit(): void {
    this.projectId = this.Auth.get('projectId');

    this.userDetail = JSON.parse(localStorage.getItem('userData') ?? '');

    if (this.Auth.roleList.isAdmin || this.Auth.roleList.isEmployee) {
      this.userId = JSON.parse(localStorage.getItem('userData') ?? '')?._id;
      this.getProject();
      // this.getProjectFromId();
      this.filterData();
    } else if (this.Auth.roleList.isClient || this.Auth.roleList.isUser) {
      this.clientData = JSON.parse(localStorage.getItem('userData'));
      this.userId = this.clientData.companyNameClient._id
        ? this.clientData.companyNameClient._id
        : this.clientData.companyNameClient;
      this.userData = JSON.parse(localStorage.getItem('userData') ?? '');
      this.getAllClientsData();
    }

    this.Auth.addNode({
      activePath: 'read-notification',
      isNotification: true,
    });
  }

  ngAfterViewInit(): void {
    if (this.Auth.roleList.isClient || this.Auth.roleList.isUser) {
      this.chartInitilize();
      this.chartsInitilize();
    }
  }

  // chart js initilization
  chartInitilize() {
    this.canvas = this.mychart.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    new Chart(this.ctx, {
      type: 'line',
      options: {
        scales: {
          xAxes: [
            {
              display: false,
            },
          ],
          yAxes: [
            {
              display: false,
            },
          ],
        },
      },
      data: {
        labels: ['2013', '2016', '2019', '2029', '2039', '2050'],
        datasets: [
          {
            label: '74 Orders',
            data: [0, 55, 7, 67, 0, 100],
            fill: true,
            borderColor: 'rgb(255, 99, 132)',
            pointBackgroundColor: 'rgb(255, 99, 132)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)',
          },
        ],
      },
    });
  }

  chartsInitilize() {
    this.newcanvas = this.newchart.nativeElement;
    this.rtx = this.newcanvas.getContext('2d');
    new Chart(this.rtx, {
      type: 'line',
      options: {
        scales: {
          xAxes: [
            {
              display: false,
            },
          ],
          yAxes: [
            {
              display: false,
            },
          ],
        },
      },
      data: {
        labels: ['2013', '2016', '2019', '2029', '2039', '2049'],
        datasets: [
          {
            label: '53 Shipment',
            data: [0, 55, 7, 67, 0, 100],
            fill: true,
            borderColor: 'rgb(255, 99, 132)',
            pointBackgroundColor: 'rgb(255, 99, 132)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(255, 99, 132)',
          },
        ],
      },
    });
  }

  // filter the data according to requirment..
  filterData() {
    $('.filter-list a').click(function (this: any) {
      let spanTagToPush = $(this).find('span').html();
      $('.filter1').html(spanTagToPush).css({
        color: '#000',
        'font-size': '0.7rem',
      });
    });

    $('.filter-list2 a').click(function (this: any) {
      let spanTagToPush = $(this).find('span').html();
      $('.filter2').html(spanTagToPush).css({
        color: '#000',
        'font-size': '0.7rem',
      });
    });

    $('.filter-list3 a').click(function (this: any) {
      let spanTagToPush = $(this).find('span').html();
      $('.filter3').html(spanTagToPush).css({
        color: '#000',
        'font-size': '0.7rem',
      });
    });
    $('.filter-list3 a').click(function (this: any) {
      let spanTagToPush = $(this).find('span').html();
      $('.filter3').html(spanTagToPush).css({
        color: '#000',
        'font-size': '0.7rem',
      });
    });
  }

  sortIcon: boolean = true;
  sortIconShow: boolean = false;
  sortIconShow2: boolean = false;
  sortIconShow3: boolean = false;
  sortIconShow4: boolean = false;
  sortIconShow5: boolean = false;
  sortIconShow6: boolean = false;
  sortIconShow7: boolean = false;
  sortIconShow8: boolean = false;
  sortIconShow9: boolean = false;
  sortIconShow10: boolean = false;
  sortIconShow11: boolean = false;
  sortIconShow12: boolean = false;
  sortIconShow13: boolean = false;
  sortIconShow14: boolean = false;
  sortIconShow15: boolean = false;
  sortIconShow16: boolean = false;

  sortIcon1: boolean = true;
  sortIcon2: boolean = true;
  sortIcon3: boolean = true;
  sortIcon4: boolean = true;
  sortIcon5: boolean = true;
  sortIcon6: boolean = true;
  sortIcon7: boolean = true;
  sortIcon8: boolean = true;
  sortIcon9: boolean = true;
  sortIcon10: boolean = true;
  sortIcon11: boolean = true;
  sortIcon12: boolean = true;
  sortIcon13: boolean = true;
  sortIcon14: boolean = true;
  sortIcon15: boolean = true;

  isSort: boolean = false;
  aSort: boolean = false;
  bSort: boolean = false;
  cSort: boolean = false;
  dSort: boolean = false;
  eSort: boolean = false;
  fSort: boolean = false;
  gSort: boolean = false;
  hSort: boolean = false;
  jSort: boolean = false;
  kSort: boolean = false;
  lSort: boolean = false;
  mSort: boolean = false;
  nSort: boolean = false;
  oSort: boolean = false;
  pSort: boolean = false;

  //** Reverse sort for priority */
  reverseSortByPriority() {
    this.isSort = !this.isSort;
    this.sortIcon = false;
    this.sortIcon1 = true;
    this.sortIcon2 = true;
    this.sortIcon3 = true;
    this.sortIcon4 = true;
    this.sortIcon5 = true;
    this.sortIcon6 = true;
    this.sortIcon7 = true;
    this.sortIcon8 = true;
    this.sortIcon9 = true;
    this.sortIcon10 = true;
    this.sortIcon11 = true;
    this.sortIcon12 = true;
    this.sortIcon13 = true;
    this.sortIcon14 = true;
    this.sortIcon15 = true;
    this.sortIconShow = true;
    this.sortIconShow2 = false;
    this.sortIconShow3 = false;
    this.sortIconShow4 = false;
    this.sortIconShow5 = false;
    this.sortIconShow6 = false;
    this.sortIconShow7 = false;
    this.sortIconShow8 = false;
    this.sortIconShow9 = false;
    this.sortIconShow10 = false;
    this.sortIconShow11 = false;
    this.sortIconShow12 = false;
    this.sortIconShow13 = false;
    this.sortIconShow14 = false;
    this.sortIconShow15 = false;
    this.sortIconShow16 = false;

    if (this.isSort) {
      this.projectList = this.projectList.sort(function (a: any, b: any) {
        var nameA = a?.project_priority?.toLowerCase(),
          nameB = b?.project_priority?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      this.projectList = this.projectList.reverse();
    }
    this.Auth.set('filterProject', this.projectList);
  }

  //** Reverse sort for status */
  reverseSortByStatus() {
    this.aSort = !this.aSort;
    this.sortIcon = true;
    this.sortIcon1 = false;
    this.sortIcon2 = true;
    this.sortIcon3 = true;
    this.sortIcon4 = true;
    this.sortIcon5 = true;
    this.sortIcon6 = true;
    this.sortIcon7 = true;
    this.sortIcon8 = true;
    this.sortIcon9 = true;
    this.sortIcon10 = true;
    this.sortIcon11 = true;
    this.sortIcon12 = true;
    this.sortIcon13 = true;
    this.sortIcon14 = true;
    this.sortIcon15 = true;
    this.sortIconShow = false;
    this.sortIconShow2 = true;
    this.sortIconShow3 = false;
    this.sortIconShow4 = false;
    this.sortIconShow5 = false;
    this.sortIconShow6 = false;
    this.sortIconShow7 = false;
    this.sortIconShow8 = false;
    this.sortIconShow9 = false;
    this.sortIconShow10 = false;
    this.sortIconShow11 = false;
    this.sortIconShow12 = false;
    this.sortIconShow13 = false;
    this.sortIconShow14 = false;
    this.sortIconShow15 = false;
    this.sortIconShow16 = false;

    if (this.aSort) {
      this.projectList = this.projectList.sort(function (a: any, b: any) {
        var nameA = a?.overall_project_status?.toLowerCase(),
          nameB = b?.overall_project_status?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      this.projectList = this.projectList.reverse();
    }
    this.Auth.set('filterProject', this.projectList);
  }

  //** Reverse sort for project status */
  reverseSortByProjectStatus() {
    this.bSort = !this.bSort;
    this.sortIcon = true;
    this.sortIcon1 = false;
    this.sortIcon2 = true;
    this.sortIcon3 = true;
    this.sortIcon4 = true;
    this.sortIcon5 = true;
    this.sortIcon6 = true;
    this.sortIcon7 = true;
    this.sortIcon8 = true;
    this.sortIcon9 = true;
    this.sortIcon10 = true;
    this.sortIcon11 = true;
    this.sortIcon12 = true;
    this.sortIcon13 = true;
    this.sortIcon14 = true;
    this.sortIcon15 = true;
    this.sortIconShow = false;
    this.sortIconShow2 = true;
    this.sortIconShow3 = false;
    this.sortIconShow4 = false;
    this.sortIconShow5 = false;
    this.sortIconShow6 = false;
    this.sortIconShow7 = false;
    this.sortIconShow8 = false;
    this.sortIconShow9 = false;
    this.sortIconShow10 = false;
    this.sortIconShow11 = false;
    this.sortIconShow12 = false;
    this.sortIconShow13 = false;
    this.sortIconShow14 = false;
    this.sortIconShow15 = false;
    this.sortIconShow16 = false;

    if (this.bSort) {
      this.projectList = this.projectList.sort(function (a: any, b: any) {
        var nameA = a?.project_stage?.toLowerCase(),
          nameB = b?.project_stage?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      this.projectList = this.projectList.reverse();
    }
  }

  //** Reverse sort for responsibility */
  reverseSortByResponsibilty() {
    this.cSort = !this.cSort;
    this.sortIcon = true;
    this.sortIcon1 = false;
    this.sortIcon2 = true;
    this.sortIcon3 = false;
    this.sortIcon4 = true;
    this.sortIcon5 = true;
    this.sortIcon6 = true;
    this.sortIcon7 = true;
    this.sortIcon8 = true;
    this.sortIcon9 = true;
    this.sortIcon10 = true;
    this.sortIcon11 = true;
    this.sortIcon12 = true;
    this.sortIcon13 = true;
    this.sortIcon14 = true;
    this.sortIcon15 = true;
    this.sortIconShow = false;
    this.sortIconShow2 = false;
    this.sortIconShow3 = false;
    this.sortIconShow4 = true;
    this.sortIconShow5 = false;
    this.sortIconShow6 = false;
    this.sortIconShow7 = false;
    this.sortIconShow8 = false;
    this.sortIconShow9 = false;
    this.sortIconShow10 = false;
    this.sortIconShow11 = false;
    this.sortIconShow12 = false;
    this.sortIconShow13 = false;
    this.sortIconShow14 = false;
    this.sortIconShow15 = false;
    this.sortIconShow16 = false;

    if (this.cSort) {
      this.projectList = this.projectList.sort(function (a: any, b: any) {
        var nameA = a?.account_manager?.name?.toLowerCase(),
          nameB = b?.account_manager?.name?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      this.projectList = this.projectList.reverse();
    }
    this.Auth.set('filterProject', this.projectList);
  }

  //** Reverse sort for client */
  reverseSortByClient() {
    this.dSort = !this.dSort;
    this.sortIcon = true;
    this.sortIcon1 = true;
    this.sortIcon2 = true;
    this.sortIcon3 = true;
    this.sortIcon4 = false;
    this.sortIcon5 = true;
    this.sortIcon6 = true;
    this.sortIcon7 = true;
    this.sortIcon8 = true;
    this.sortIcon9 = true;
    this.sortIcon10 = true;
    this.sortIcon11 = true;
    this.sortIcon12 = true;
    this.sortIcon13 = true;
    this.sortIcon14 = true;
    this.sortIcon15 = true;
    this.sortIconShow = false;
    this.sortIconShow2 = false;
    this.sortIconShow3 = false;
    this.sortIconShow4 = false;
    this.sortIconShow5 = true;
    this.sortIconShow6 = false;
    this.sortIconShow7 = false;
    this.sortIconShow8 = false;
    this.sortIconShow9 = false;
    this.sortIconShow10 = false;
    this.sortIconShow11 = false;
    this.sortIconShow12 = false;
    this.sortIconShow13 = false;
    this.sortIconShow14 = false;
    this.sortIconShow15 = false;
    this.sortIconShow16 = false;

    if (this.dSort) {
      this.projectList = this.projectList.sort(function (a: any, b: any) {
        var nameA = a?.company_name?.company_name?.toLowerCase(),
          nameB = b?.company_name?.company_name?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      this.projectList = this.projectList.reverse();
    }
    this.Auth.set('filterProject', this.projectList);
  }

  reverseSortBySupplier() {
    this.hSort = !this.hSort;
    this.sortIcon = true;
    this.sortIcon1 = true;
    this.sortIcon2 = true;
    this.sortIcon3 = true;
    this.sortIcon4 = true;
    this.sortIcon5 = true;
    this.sortIcon6 = true;
    this.sortIcon7 = true;
    this.sortIcon8 = false;
    this.sortIcon9 = true;
    this.sortIcon10 = true;
    this.sortIcon11 = true;
    this.sortIcon12 = true;
    this.sortIcon13 = true;
    this.sortIcon14 = true;
    this.sortIcon15 = true;
    this.sortIconShow = false;
    this.sortIconShow2 = false;
    this.sortIconShow3 = false;
    this.sortIconShow4 = false;
    this.sortIconShow5 = false;
    this.sortIconShow6 = false;
    this.sortIconShow7 = false;
    this.sortIconShow8 = false;
    this.sortIconShow9 = true;
    this.sortIconShow10 = false;
    this.sortIconShow11 = false;
    this.sortIconShow12 = false;
    this.sortIconShow13 = false;
    this.sortIconShow14 = false;
    this.sortIconShow15 = false;
    this.sortIconShow16 = false;

    if (this.hSort) {
      this.projectList = this.projectList.sort(function (a: any, b: any) {
        var nameA = a?.supplier[0]?.company_name?.toLowerCase(),
          nameB = b?.supplier[0]?.company_name?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      this.projectList = this.projectList.reverse();
    }
    this.Auth.set('filterProject', this.projectList);
  }
  reverseSortBySupplierCategory() {
    this.mSort = !this.mSort;
    this.sortIcon = true;
    this.sortIcon1 = true;
    this.sortIcon2 = true;
    this.sortIcon3 = true;
    this.sortIcon4 = true;
    this.sortIcon5 = true;
    this.sortIcon6 = true;
    this.sortIcon7 = true;
    this.sortIcon8 = true;
    this.sortIcon9 = true;
    this.sortIcon10 = true;
    this.sortIcon11 = true;
    this.sortIcon12 = false;
    this.sortIcon13 = true;
    this.sortIcon14 = true;
    this.sortIcon15 = true;
    this.sortIconShow = false;
    this.sortIconShow2 = false;
    this.sortIconShow3 = false;
    this.sortIconShow4 = true;
    this.sortIconShow5 = false;
    this.sortIconShow6 = false;
    this.sortIconShow7 = false;
    this.sortIconShow8 = false;
    this.sortIconShow9 = false;
    this.sortIconShow10 = false;
    this.sortIconShow11 = false;
    this.sortIconShow12 = false;
    this.sortIconShow13 = true;
    this.sortIconShow14 = false;
    this.sortIconShow15 = false;
    this.sortIconShow16 = false;

    if (this.mSort) {
      this.projectList = this.projectList.sort(function (a: any, b: any) {
        var nameA = a?.supplier[0]?.category?.toLowerCase(),
          nameB = b?.supplier[0]?.category?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      this.projectList = this.projectList.reverse();
    }
    this.Auth.set('filterProject', this.projectList);
  }
  reverseSortBySupplierSubCategory() {
    this.nSort = !this.nSort;
    this.sortIcon = true;
    this.sortIcon1 = true;
    this.sortIcon2 = true;
    this.sortIcon3 = true;
    this.sortIcon4 = true;
    this.sortIcon5 = true;
    this.sortIcon6 = true;
    this.sortIcon7 = true;
    this.sortIcon8 = true;
    this.sortIcon9 = true;
    this.sortIcon10 = true;
    this.sortIcon11 = true;
    this.sortIcon12 = true;
    this.sortIcon13 = false;
    this.sortIcon14 = true;
    this.sortIcon15 = true;
    this.sortIconShow = false;
    this.sortIconShow2 = false;
    this.sortIconShow3 = false;
    this.sortIconShow4 = true;
    this.sortIconShow5 = false;
    this.sortIconShow6 = false;
    this.sortIconShow7 = false;
    this.sortIconShow8 = false;
    this.sortIconShow9 = false;
    this.sortIconShow10 = false;
    this.sortIconShow11 = false;
    this.sortIconShow12 = false;
    this.sortIconShow13 = false;
    this.sortIconShow14 = true;
    this.sortIconShow15 = false;
    this.sortIconShow16 = false;

    if (this.nSort) {
      this.projectList = this.projectList.sort(function (a: any, b: any) {
        var nameA = a?.supplier[0]?.sub_category?.toLowerCase(),
          nameB = b?.supplier[0]?.sub_category?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      this.projectList = this.projectList.reverse();
    }
    this.Auth.set('filterProject', this.projectList);
  }

  reverseSortByDueDate() {
    this.oSort = !this.oSort;
    this.sortIcon = true;
    this.sortIcon1 = true;
    this.sortIcon2 = true;
    this.sortIcon3 = true;
    this.sortIcon4 = true;
    this.sortIcon5 = true;
    this.sortIcon6 = true;
    this.sortIcon7 = true;
    this.sortIcon8 = true;
    this.sortIcon9 = true;
    this.sortIcon10 = true;
    this.sortIcon11 = true;
    this.sortIcon12 = true;
    this.sortIcon13 = true;
    this.sortIcon14 = false;
    this.sortIcon15 = true;
    this.sortIconShow = false;
    this.sortIconShow2 = false;
    this.sortIconShow3 = false;
    this.sortIconShow4 = true;
    this.sortIconShow5 = false;
    this.sortIconShow6 = false;
    this.sortIconShow7 = false;
    this.sortIconShow8 = false;
    this.sortIconShow9 = false;
    this.sortIconShow10 = false;
    this.sortIconShow11 = false;
    this.sortIconShow12 = false;
    this.sortIconShow13 = false;
    this.sortIconShow14 = false;
    this.sortIconShow15 = true;
    this.sortIconShow16 = false;

    if (this.oSort) {
      this.projectList = this.projectList.sort(function (a: any, b: any) {
        var nameA = a?.due_date?.toLowerCase(),
          nameB = b?.due_date?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      this.projectList = this.projectList.reverse();
    }
    this.Auth.set('filterProject', this.projectList);
  }

  //** Reverse sort for project */
  reverseSortByProject() {
    this.eSort = !this.eSort;
    this.sortIcon = true;
    this.sortIcon1 = true;
    this.sortIcon2 = true;
    this.sortIcon3 = true;
    this.sortIcon4 = true;
    this.sortIcon5 = false;
    this.sortIcon6 = true;
    this.sortIcon7 = true;
    this.sortIcon8 = true;
    this.sortIcon9 = true;
    this.sortIcon10 = true;
    this.sortIcon11 = true;
    this.sortIcon12 = true;
    this.sortIcon13 = true;
    this.sortIcon14 = true;
    this.sortIcon15 = true;
    this.sortIconShow = false;
    this.sortIconShow2 = false;
    this.sortIconShow3 = false;
    this.sortIconShow4 = true;
    this.sortIconShow5 = false;
    this.sortIconShow6 = true;
    this.sortIconShow7 = false;
    this.sortIconShow8 = false;
    this.sortIconShow9 = false;
    this.sortIconShow10 = false;
    this.sortIconShow11 = false;
    this.sortIconShow12 = false;
    this.sortIconShow13 = false;
    this.sortIconShow14 = false;
    this.sortIconShow15 = false;
    this.sortIconShow16 = false;

    if (this.eSort) {
      this.projectList = this.projectList.sort(function (a: any, b: any) {
        var nameA = a?.name?.toLowerCase(),
          nameB = b?.name?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      this.projectList = this.projectList.reverse();
    }
    this.Auth.set('filterProject', this.projectList);
  }

  //** Reverse sort for summary */
  reverseSortBySummary() {
    this.fSort = !this.fSort;
    this.sortIcon = true;
    this.sortIcon1 = true;
    this.sortIcon2 = true;
    this.sortIcon3 = true;
    this.sortIcon4 = true;
    this.sortIcon5 = true;
    this.sortIcon6 = false;
    this.sortIcon7 = true;
    this.sortIcon8 = true;
    this.sortIcon9 = true;
    this.sortIcon10 = true;
    this.sortIcon11 = true;
    this.sortIcon12 = false;
    this.sortIcon13 = true;
    this.sortIcon14 = true;
    this.sortIcon15 = true;
    this.sortIconShow = false;
    this.sortIconShow2 = false;
    this.sortIconShow3 = false;
    this.sortIconShow4 = false;
    this.sortIconShow5 = false;
    this.sortIconShow6 = false;
    this.sortIconShow7 = true;
    this.sortIconShow8 = false;
    this.sortIconShow9 = false;
    this.sortIconShow10 = false;
    this.sortIconShow11 = false;
    this.sortIconShow12 = false;
    this.sortIconShow13 = false;
    this.sortIconShow14 = false;
    this.sortIconShow15 = false;
    this.sortIconShow16 = false;

    if (this.fSort) {
      this.projectList = this.projectList.sort(function (a: any, b: any) {
        var nameA = a?.summary_of_status?.toLowerCase(),
          nameB = b?.summary_of_status?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      this.projectList = this.projectList.reverse();
    }
    this.Auth.set('filterProject', this.projectList);
  }

  //** Reverse sort for Blockages */
  reverseSortByBlockages() {
    this.gSort = !this.gSort;
    this.sortIcon = true;
    this.sortIcon1 = true;
    this.sortIcon2 = true;
    this.sortIcon3 = true;
    this.sortIcon4 = true;
    this.sortIcon5 = true;
    this.sortIcon6 = true;
    this.sortIcon7 = false;
    this.sortIcon8 = true;
    this.sortIcon9 = true;
    this.sortIcon10 = true;
    this.sortIcon11 = true;
    this.sortIcon12 = true;
    this.sortIcon13 = true;
    this.sortIcon14 = true;
    this.sortIcon15 = true;
    this.sortIconShow = false;
    this.sortIconShow2 = false;
    this.sortIconShow3 = false;
    this.sortIconShow4 = false;
    this.sortIconShow5 = false;
    this.sortIconShow6 = false;
    this.sortIconShow7 = false;
    this.sortIconShow8 = true;
    this.sortIconShow9 = false;
    this.sortIconShow10 = false;
    this.sortIconShow11 = false;
    this.sortIconShow12 = false;
    this.sortIconShow13 = false;
    this.sortIconShow14 = false;
    this.sortIconShow15 = false;
    this.sortIconShow16 = false;

    if (this.gSort) {
      this.projectList = this.projectList.sort(function (a: any, b: any) {
        var nameA = a?.blockages?.toLowerCase(),
          nameB = b?.blockages?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      this.projectList = this.projectList.reverse();
    }
  }

  //** Reverse sort for Next step */
  reverseSortByNextStep() {
    this.jSort = !this.jSort;
    this.sortIcon = true;
    this.sortIcon1 = true;
    this.sortIcon2 = true;
    this.sortIcon3 = true;
    this.sortIcon4 = true;
    this.sortIcon5 = true;
    this.sortIcon6 = true;
    this.sortIcon7 = true;
    this.sortIcon8 = true;
    this.sortIcon9 = false;
    this.sortIcon10 = true;
    this.sortIcon11 = true;
    this.sortIcon12 = true;
    this.sortIcon13 = true;
    this.sortIcon14 = true;
    this.sortIcon15 = true;
    this.sortIconShow = false;
    this.sortIconShow2 = false;
    this.sortIconShow3 = false;
    this.sortIconShow4 = false;
    this.sortIconShow5 = false;
    this.sortIconShow6 = false;
    this.sortIconShow7 = false;
    this.sortIconShow8 = false;
    this.sortIconShow9 = false;
    this.sortIconShow10 = true;
    this.sortIconShow11 = false;
    this.sortIconShow12 = false;
    this.sortIconShow13 = false;
    this.sortIconShow14 = false;
    this.sortIconShow15 = false;
    this.sortIconShow16 = false;

    if (this.jSort) {
      this.projectList = this.projectList.sort(function (a: any, b: any) {
        var nameA = a?.next_step?.toLowerCase(),
          nameB = b?.next_step?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      this.projectList = this.projectList.reverse();
    }
  }

  reverseSortByProjectDescription() {
    this.kSort = !this.kSort;
    this.sortIcon = true;
    this.sortIcon1 = true;
    this.sortIcon2 = true;
    this.sortIcon3 = true;
    this.sortIcon4 = true;
    this.sortIcon5 = true;
    this.sortIcon6 = true;
    this.sortIcon7 = true;
    this.sortIcon8 = true;
    this.sortIcon9 = true;
    this.sortIcon10 = false;
    this.sortIcon11 = true;
    this.sortIcon12 = true;
    this.sortIcon13 = true;
    this.sortIcon14 = true;
    this.sortIcon15 = true;
    this.sortIconShow = false;
    this.sortIconShow2 = false;
    this.sortIconShow3 = false;
    this.sortIconShow4 = false;
    this.sortIconShow5 = false;
    this.sortIconShow6 = false;
    this.sortIconShow7 = false;
    this.sortIconShow8 = false;
    this.sortIconShow9 = false;
    this.sortIconShow10 = false;
    this.sortIconShow11 = true;
    this.sortIconShow12 = false;
    this.sortIconShow13 = false;
    this.sortIconShow14 = false;
    this.sortIconShow15 = false;
    this.sortIconShow16 = false;

    if (this.kSort) {
      this.projectList = this.projectList.sort(function (a: any, b: any) {
        var nameA = a?.project_description?.toLowerCase(),
          nameB = b?.project_description?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      this.projectList = this.projectList.reverse();
    }
    this.Auth.set('filterProject', this.projectList);
  }
  reverseSortByMessageToTeam() {
    this.lSort = !this.lSort;
    this.sortIcon = true;
    this.sortIcon1 = true;
    this.sortIcon2 = true;
    this.sortIcon3 = true;
    this.sortIcon4 = true;
    this.sortIcon5 = true;
    this.sortIcon6 = true;
    this.sortIcon7 = true;
    this.sortIcon8 = true;
    this.sortIcon9 = true;
    this.sortIcon10 = true;
    this.sortIcon11 = false;
    this.sortIcon12 = true;
    this.sortIcon13 = true;
    this.sortIcon14 = true;
    this.sortIcon15 = true;
    this.sortIconShow = false;
    this.sortIconShow2 = false;
    this.sortIconShow3 = false;
    this.sortIconShow4 = false;
    this.sortIconShow5 = false;
    this.sortIconShow6 = false;
    this.sortIconShow7 = false;
    this.sortIconShow8 = false;
    this.sortIconShow9 = false;
    this.sortIconShow10 = false;
    this.sortIconShow11 = false;
    this.sortIconShow12 = true;
    this.sortIconShow13 = false;
    this.sortIconShow14 = false;
    this.sortIconShow15 = false;
    this.sortIconShow16 = false;

    if (this.lSort) {
      this.projectList = this.projectList.sort(function (a: any, b: any) {
        var nameA = a?.message_to_team?.toLowerCase(),
          nameB = b?.message_to_team?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      this.projectList = this.projectList.reverse();
    }
    this.Auth.set('filterProject', this.projectList);
  }

  //** Refresh page function */
  refreshPage() {
    // this.getProjectFromId();
    this.getProject();
    this.Auth.set('filterProject', null);

    $('.filter1').html('Filter').css({
      color: '#c8beb3',
    });
    $('.filter2').html('Filter').css({
      color: '#c8beb3',
    });
    $('.filter3').html('Filter').css({
      color: '#c8beb3',
    });
    $('.filter4').html('Filter').css({
      color: '#c8beb3',
    });
    $('.filter5').html('Filter').css({
      color: '#c8beb3',
    });
    $('.filter6').html('Filter').css({
      color: '#c8beb3',
    });
    this.filterCategories = {
      priority: '',
      currentStatus: '',
      projectStage: '',
      responsibility: '',
      clinetName: '',
      supplierName: '',
      overall_project_status: '',
    };

    this.searchProject = '';
    this.Status = '';
    this.blockage = '';
    this.nextSteps = '';
    this.searchAll = '';
    this.sortIcon = true;
    this.sortIcon1 = true;
    this.sortIcon2 = true;
    this.sortIcon3 = true;
    this.sortIcon4 = true;
    this.sortIcon5 = true;
    this.sortIcon6 = true;
    this.sortIcon7 = true;
    this.sortIcon8 = true;
    this.sortIcon9 = true;
    this.sortIcon10 = true;
    this.sortIcon11 = true;
    this.sortIcon12 = true;
    this.sortIcon13 = true;
    this.sortIcon14 = true;
    this.sortIcon15 = true;
    this.sortIconShow = false;
    this.sortIconShow2 = false;
    this.sortIconShow3 = false;
    this.sortIconShow4 = false;
    this.sortIconShow5 = false;
    this.sortIconShow6 = false;
    this.sortIconShow7 = false;
    this.sortIconShow8 = false;
    this.sortIconShow9 = false;
    this.sortIconShow10 = false;
    this.sortIconShow11 = false;
    this.sortIconShow12 = false;
    this.sortIconShow13 = false;
    this.sortIconShow14 = false;
    this.sortIconShow15 = false;
    this.sortIconShow16 = false;

    this.multiFilter = {
      currentStatus: [],
      filterPriorities: [],
      overAllStatus: [],
      accountManager: [],
      companyName: [],
      supplierName: [],
    };
  }

  reverseSortByOverallStatus() {
    this.pSort = !this.pSort;
    this.sortIcon = true;
    this.sortIcon1 = true;
    this.sortIcon2 = true;
    this.sortIcon3 = true;
    this.sortIcon4 = true;
    this.sortIcon5 = true;
    this.sortIcon6 = true;
    this.sortIcon7 = true;
    this.sortIcon8 = true;
    this.sortIcon9 = true;
    this.sortIcon10 = true;
    this.sortIcon11 = true;
    this.sortIcon12 = true;
    this.sortIcon13 = true;
    this.sortIcon14 = true;
    this.sortIcon15 = false;
    this.sortIconShow = false;
    this.sortIconShow2 = false;
    this.sortIconShow3 = false;
    this.sortIconShow4 = false;
    this.sortIconShow5 = false;
    this.sortIconShow6 = false;
    this.sortIconShow7 = false;
    this.sortIconShow8 = false;
    this.sortIconShow9 = false;
    this.sortIconShow10 = false;
    this.sortIconShow11 = false;
    this.sortIconShow12 = false;
    this.sortIconShow13 = false;
    this.sortIconShow14 = false;
    this.sortIconShow15 = false;
    this.sortIconShow16 = true;

    if (this.pSort) {
      this.projectList = this.projectList.sort(function (a: any, b: any) {
        var nameA = a?.overall_project_status?.toLowerCase(),
          nameB = b?.overall_project_status?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    } else {
      this.projectList = this.projectList.reverse();
    }
    this.Auth.set('filterProject', this.projectList);
  }

  //** Search Client */
  clientName(event: any) {
    this.searchProject = '';
    if (event != null) {
      const search = this.Auth.get('dashProjects').filter((e: any) =>
        e?.company_name?.toLowerCase().includes(event.toLowerCase())
      );
      this.projectList = search;
    }
  }

  supplierName(event: any) {
    // this.filterCategories.supplierName = event?.target.innerText;
    this.filterCategories.supplierName = event;

    let target = event.target;

    let spanTagToPush = $(target).text();
    let spanDataToPush = $(`<span>${spanTagToPush}</span>`);
    $('.filter6').html(spanDataToPush).css({
      color: 'black',
    });
    // this.projectList = this.FilterCategoryCommonFunction(
    //   (this.filterCategories.supplierName = event?.target.innerText)
    // );
    this.projectList = this.FilterCategoryCommonFunction(
      (this.filterCategories.supplierName = event)
    );
    this.Auth.set('filterProject', this.projectList);
  }

  //** Search All project  */
  searchAllProject(event: any) {
    if (event != null) {
      const search = this.Auth.get('dashProjects').filter((e: any) =>
        e?.name?.toLowerCase().includes(event.toLowerCase())
      );
      this.projectList = search;
      this.Auth.set('filterProject', this.projectList);
    }
  }

  //** Search project*/
  searchProjects(event: any) {
    this.Status = '';
    this.blockage = '';
    this.nextSteps = '';
    this.searchAll = '';
    this.project_description = '';
    this.message_to_team = '';
    this.supplierSubCategory = '';
    this.supplierCategory = '';
    if (event != null) {
      const search = this.Auth.get('dashProjects').filter((e: any) =>
        e?.name?.toLowerCase().includes(event.toLowerCase())
      );
      this.projectList = search;
    }
    if (event == '') {
      this.projectList = this.Auth.get('dashProjects');
    }
    this.Auth.set('filterProject', this.projectList);
  }

  // //** filter by due-date */
  searchByDueDate(event: any) {
    let eventDate = new Date(event);

    if (event) {
      const search = this.Auth.get('dashProjects').filter((e: any) => {
        const dueDate = new Date(e.due_date);
        return (
          dueDate.getUTCFullYear() === eventDate.getUTCFullYear() &&
          dueDate.getUTCMonth() === eventDate.getUTCMonth() &&
          dueDate.getUTCDate() === eventDate.getUTCDate()
        );
      });
      this.projectList = search;
    } else {
      this.projectList = this.Auth.get('dashProjects');
    }
    this.Auth.set('filterProject', this.projectList);
  }

  //** filter next step data */
  nextStep(event: any) {
    this.searchProject = '';
    this.Status = '';
    this.blockage = '';
    this.project_description = '';
    this.message_to_team = '';
    this.supplierSubCategory = '';
    if (event != null) {
      const search = this.Auth.get('dashProjects').filter((e: any) =>
        e?.next_step?.toLowerCase().includes(event?.toLowerCase())
      );
      this.projectList = search;
    }
    if (event == '') {
      this.projectList = this.Auth.get('dashProjects');
    }
  }

  //** Search as per project description */
  projectDescription(event: any) {
    this.searchProject = '';
    this.Status = '';
    this.blockage = '';
    this.message_to_team = '';
    this.supplierSubCategory = '';
    this.supplierCategory = '';
    if (event != null) {
      const search = this.Auth.get('dashProjects').filter((e: any) =>
        e?.project_description?.toLowerCase().includes(event?.toLowerCase())
      );
      this.projectList = search;
    }
    if (event == '') {
      this.projectList = this.Auth.get('dashProjects');
    }

    this.Auth.set('filterProject', this.projectList);
  }

  //** Search category of supplier */
  supplierCategorySearch(event: any) {
    this.searchProject = '';
    this.Status = '';
    this.blockage = '';
    this.message_to_team = '';
    this.supplierSubCategory = '';
    if (event != null) {
      const search = this.Auth.get('dashProjects').filter((e: any) =>
        e?.supplier[0]?.category?.toLowerCase().includes(event?.toLowerCase())
      );
      this.projectList = search;
    }
    if (event == '') {
      this.projectList = this.Auth.get('dashProjects');
    }
    this.Auth.set('filterProject', this.projectList);
  }

  //** Search sub-category of supplier */
  supplierSubCategorySearch(event: any) {
    this.searchProject = '';
    this.Status = '';
    this.blockage = '';
    this.message_to_team = '';
    this.supplierCategory = '';
    if (event != null) {
      const search = this.Auth.get('dashProjects').filter((e: any) =>
        e?.supplier[0]?.sub_category
          ?.toLowerCase()
          .includes(event?.toLowerCase())
      );
      this.projectList = search;
    }
    if (event == '') {
      this.projectList = this.Auth.get('dashProjects');
    }
    this.Auth.set('filterProject', this.projectList);
  }

  //** Search as per message to team */
  messageToTeam(event: any) {
    this.searchProject = '';
    this.Status = '';
    this.blockage = '';
    this.project_description = '';
    if (event != null) {
      const search = this.Auth.get('dashProjects').filter((e: any) =>
        e?.message_to_team?.toLowerCase().includes(event?.toLowerCase())
      );
      this.projectList = search;
    }
    if (event == '') {
      this.projectList = this.Auth.get('dashProjects');
    }

    this.Auth.set('filterProject', this.projectList);
  }

  //** filter blockage data */
  blockages(event: any) {
    this.searchProject = '';
    this.Status = '';
    this.nextSteps = '';
    if (event != null) {
      const search = this.Auth.get('dashProjects').filter((e: any) =>
        e?.blockages?.toLowerCase().includes(event?.toLowerCase())
      );
      this.projectList = search;
    }
    if (event == '') {
      this.projectList = this.Auth.get('dashProjects');
    }
  }

  //** filter summary status data */
  SummaryStatus(event: any) {
    this.searchProject = '';
    this.blockage = '';
    this.nextSteps = '';
    this.supplierCategory = '';
    this.supplierSubCategory = '';
    if (event != null) {
      const search = this.Auth.get('dashProjects').filter((e: any) =>
        // e?.summary_of_status?.toLowerCase().includes(event?.toLowerCase())

        e?.summary.some((status) =>
          status?.summary_of_status.toLowerCase().includes(event?.toLowerCase())
        )
      );
      this.projectList = search;
    }
    if (event == '') {
      this.projectList = this.Auth.get('dashProjects');
    }

    this.Auth.set('filterProject', this.projectList);
  }
  /////////////////////////////////////
  // filter section
  /////////////////////////////////////

  FilterCategoryCommonFunction(x: any) {
    const search = this.Auth.get('dashProjects').filter(
      (e: any) =>
        (this.filterCategories.priority
          ? this.filterCategories.priority == ''
            ? e?.project_priority == e?.project_priority
            : e?.project_priority ==
              this.filterCategories.priority?.filter((el) =>
                e?.project_priority?.includes(el)
              )
          : x?.filter((el) => e?.project_priority?.includes(el))) &&
        (this.filterCategories.currentStatus
          ? this.filterCategories.currentStatus == ''
            ? e?.schedule_status == e?.schedule_status
            : e?.schedule_status ==
              this.filterCategories.currentStatus?.filter((el) =>
                e?.schedule_status?.includes(el)
              )
          : x?.filter((el) => e?.schedule_status?.includes(el))) &&
        (this.filterCategories.projectStage
          ? this.filterCategories.projectStage == 'No Filter'
            ? e?.current_project_stage == e?.current_project_stage
            : e?.current_project_stage == this.filterCategories.projectStage
          : x) &&
        (this.filterCategories.overall_project_status
          ? this.filterCategories.overall_project_status == ''
            ? e?.overall_project_status == e?.overall_project_status
            : e?.overall_project_status ==
              this.filterCategories.overall_project_status?.filter((el) =>
                e?.overall_project_status?.includes(el)
              )
          : x?.filter((el) => e?.overall_project_status?.includes(el))) &&
        (this.filterCategories.responsibility
          ? this.filterCategories.responsibility == ''
            ? e?.account_manager?.name == e?.account_manager?.name
            : e?.account_manager?.name ==
              this.filterCategories.responsibility?.filter((el) =>
                e?.account_manager?.name?.includes(el)
              )
          : x?.filter((el) => e?.account_manager?.name?.includes(el))) &&
        (this.filterCategories.clinetName
          ? this.filterCategories.clinetName == ''
            ? e?.company_name?.company_name == e?.company_name?.company_name
            : e?.company_name?.company_name ==
              this.filterCategories?.clinetName?.filter((el) =>
                e?.company_name?.company_name?.includes(el)
              )
          : x?.filter((el) => e?.company_name?.company_name?.includes(el))) &&
        (this.filterCategories.supplierName
          ? this.filterCategories.supplierName == ''
            ? e?.supplier[0]?.company_name == e?.supplier[0]?.company_name
            : e?.supplier[0]?.company_name ==
              this.filterCategories.supplierName?.filter((el) =>
                e?.supplier[0]?.company_name?.includes(el)
              )
          : x?.filter((el) => e?.supplier[0]?.company_name?.includes(el)))
    );
    return search;
  }

  // priority filter function....
  range(event: any) {
    // this.filterCategories.priority = event?.target.innerText;
    this.filterCategories.priority = event;

    // this.projectList = this.FilterCategoryCommonFunction(
    //   (this.filterCategories.priority = event?.target.innerText)
    // );
    this.projectList = this.FilterCategoryCommonFunction(
      (this.filterCategories.priority = event)
    );

    this.Auth.set('filterProject', this.projectList);
  }

  //** Overall project status filter */
  overallStatus(event: any) {
    // this.filterCategories.overall_project_status = event?.target.innerText;
    this.filterCategories.overall_project_status = event;

    // this.projectList = this.FilterCategoryCommonFunction(
    //   (this.filterCategories.overall_project_status = event?.target.innerText)
    // );
    this.projectList = this.FilterCategoryCommonFunction(
      (this.filterCategories.overall_project_status = event)
    );

    this.Auth.set('filterProject', this.projectList);
  }

  // current status filter....
  time(event: any) {
    // this.filterCategories.currentStatus = event?.target.innerText;
    this.filterCategories.currentStatus = event;

    // this.projectList = this.FilterCategoryCommonFunction(
    //   (this.filterCategories.currentStatus = event?.target.innerText)
    // );
    this.projectList = this.FilterCategoryCommonFunction(
      (this.filterCategories.currentStatus = event)
    );
    this.Auth.set('filterProject', this.projectList);
  }

  // Project stage filter..
  stages(event: any) {
    this.filterCategories.projectStage = event?.target.innerText;

    this.projectList = this.FilterCategoryCommonFunction(
      (this.filterCategories.projectStage = event?.target.innerText)
    );
    this.Auth.set('filterProject', this.projectList);
  }

  // responsibility filter..
  accountManager(event: any) {
    // this.filterCategories.responsibility = event?.target.innerText;
    this.filterCategories.responsibility = event;

    let target = event.target;
    let spanTagToPush = $(target).text();
    let spanDataToPush = $(`<span>${spanTagToPush}</span>`);
    $('.filter4 ').html(spanDataToPush).css({
      color: 'black',
    });

    // this.projectList = this.FilterCategoryCommonFunction(
    //   (this.filterCategories.responsibility = event?.target.innerText)
    // );
    this.projectList = this.FilterCategoryCommonFunction(
      (this.filterCategories.responsibility = event)
    );

    this.Auth.set('filterProject', this.projectList);
  }

  //  client name or company name filter
  companyName(event: any) {
    // this.filterCategories.clinetName = event?.target.innerText;
    this.filterCategories.clinetName = event;

    let target = event.target;

    let spanTagToPush = $(target).text();
    let spanDataToPush = $(`<span>${spanTagToPush}</span>`);
    $('.filter5 ').html(spanDataToPush).css({
      color: 'black',
    });
    // this.projectList = this.FilterCategoryCommonFunction(
    //   (this.filterCategories.clinetName = event?.target.innerText)
    // );
    this.projectList = this.FilterCategoryCommonFunction(
      (this.filterCategories.clinetName = event)
    );
    this.Auth.set('filterProject', this.projectList);
  }

  responsible(event: any) {
    const search = this.Auth.get('dashProjects').filter(
      (e: any) => e?.account_manager?.name == event?.target?.innerText
    );
    this.projectList = search;
  }

  //** Getting project data from ID */
  getProject() {
    try {
      if (this.Auth.roleList.isAdmin || this.Auth.roleList.isEmployee) {
        this.http
          .request('get', '/employee/project/' + this.userId, null)
          .subscribe((response: any) => {
            this.projectList = response.results;

            this.projectList = this.projectList.map((x: any) => {
              if (x.due_date) {
                x.due_date = new Date(x.due_date);
              }
            });

            this.suppliername = response.results;

            this.projectList = response.results.filter((project) => {
              return (
                project.overall_project_status !== 'On Hold' &&
                project.overall_project_status !== 'Completed'
              );
            });

            this.responsibility = response.results;
            this.clientname = response.results;
            this.Auth.set('dashProjects', this.projectList);

            // Memo: Commented out logic get previous results because filter not kept but data is kept => Not correct
            //** to get pervious filter data if any */
            // if (
            //   (this.Auth.get('filterProject') != null ||
            //     this.Auth.get('filterProject') != undefined) &&
            //   this.Auth.get('filterProject').length > 0
            // ) {
            //   this.projectList = this.Auth.get('filterProject');
            // }
          });
      } else {
      }
    } catch (error) {}
  }

  //** Getting project data from ID */
  getProjectFromId() {
    this.projectList = [];
    try {
      this.http
        .request('get', '/employee/project/' + this.userId, null)
        .subscribe((response: any) => {
          // this.projectList = response.results;
          this.projectList.push(
            ...response.results.filter((e: any) => e.project_priority == 'High')
          );
          this.projectList.push(
            ...response.results.filter(
              (e: any) => e.project_priority == 'Medium'
            )
          );
          this.projectList.push(
            ...response.results.filter((e: any) => e.project_priority == 'Low')
          );
          this.projectList.push(
            ...response.results.filter((e: any) => e.project_priority == null)
          );

          this.responsibility = response.results;
          this.clientname = response.results;
          this.suppliername = response.results;

          this.Auth.set('dashProjects', this.projectList);
        });
    } catch (error) {}
  }

  //** Filter Company name */
  filterCompanyName() {
    let companyName = this.clientname.map(
      (e: any) => e?.company_name?.company_name
    );

    let result = companyName.filter((item: any, pos: any) => {
      if (item) return companyName.indexOf(item) == pos;
    });

    return result;
  }
  filterSupplierName() {
    let supplierName = this.suppliername.map(
      (e: any) => e?.supplier[0]?.company_name
    );

    let result = supplierName.filter((item: any, pos: any) => {
      return supplierName.indexOf(item ? item : null) == pos;
    });
    return result;
  }

  filterSupplierCategory() {
    let supplierName = this.suppliername.map(
      (e: any) => e?.supplier[0]?.category
    );

    let result = supplierName.filter((item: any, pos: any) => {
      return supplierName.indexOf(item ? item : null) == pos;
    });
    return result;
  }
  filterSupplierSubCategory() {
    let supplierName = this.suppliername.map(
      (e: any) => e?.supplier[0]?.sub_category
    );

    let result = supplierName.filter((item: any, pos: any) => {
      return supplierName.indexOf(item ? item : null) == pos;
    });
    return result;
  }

  //** Filter Responsibility name */
  filterResponsibilityName() {
    let responsibilityName = this.clientname.map((e: any) =>
      e.account_manager ? e.account_manager.name : null
    );

    let result = responsibilityName.filter((item: any, pos: any) => {
      if (item) {
        return responsibilityName.indexOf(item) == pos;
      }
    });
    return result;
  }

  //** Navigate to project using ID */
  goToTask(x: any) {
    this.Auth.set('projectNavigate', { id: x._id, item: x });
    this.router.navigate(['/project/' + x.company_name._id + '/task']);
    localStorage.setItem('projectSelected', x.item);
  }

  //** Getting dashProject from setter */
  getProjectItem() {
    return this.Auth.get('dashProjects');
  }

  // clients

  clientProjectList: any = [];
  clientStatus: any = [];

  //** Getting client data */
  getAllClientsData() {
    try {
      this.http
        .request('get', '/client/project/getProject/' + this.userId, null)
        .subscribe((response: any) => {
          this.clientProjectList = response.results;
          this.clientStatus = response.results.section;
        });
    } catch (error) {}
  }

  //** Navigate to task using ID */
  goToTaskClient(x: any) {
    this.Auth.set('projectNavigate', { id: x._id, item: x });
    this.router.navigate(['/project/' + x.company_name + '/task']);
  }

  //** Changing future step label */
  getProjectStatus(status: any) {
    if (status == 'Future_step') {
      return 'Future Step';
    } else if (status == 'Inprogress') {
      return 'In Progress';
    } else return status;
  }

  //** comparing release date for project status */
  status = '';
  projectDuration(date: any) {
    const date1 = new Date();
    const date2 = new Date(date);

    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    if (date1.getTime() >= date2.getTime()) {
      return (this.status = 'late');
    } else if (diffInDays <= 3 || diffInDays == 0) {
      return (this.status = 'atRisk');
    } else {
      return (this.status = 'ontime');
    }
  }

  //** open summary of status pop-up */
  openSummary(data: any) {
    this.summaryStatusData = data;

    this.modalRef = this.modal.show(this.summaryStatus, {
      backdrop: 'static',
      ignoreBackdropClick: false,
      class: 'modal-lg modal-dialog-centered',
    });
  }

  //** update project priority */
  updateProject(project: any) {
    try {
      let rqData = {
        project_priority: project.project_priority,
        overall_project_status: project.overall_project_status,
        due_date: project.due_date,
      };

      this.http
        .request('put', '/employee/project/' + project._id, rqData)
        .subscribe((response: any) => {
          this.projectList.filter((x: any) => {
            if (x._id == project._id) {
              x.project_priority = response?.project?.project_priority;
              x.overall_project_status =
                response?.project?.overall_project_status;
            }
          });

          this.projectList = this.projectList.filter((project) => {
            return (
              project.overall_project_status !== 'On Hold' &&
              project.overall_project_status !== 'Completed'
            );
          });
        });
    } catch (error) {}
  }

  //** remove archive archive from the list of overall_project_status */
  removeArchive(x: any) {
    return x.filter((e) => e != 'Archive');
  }

  //** Open Datepicker on click */
  openDatePicker(project: any) {
    $(`.${project._id}`).click();
  }
}
