<div *ngIf="Auth.getUserRole()">
  <div class="bg-light">
    <div
      class="leftside-menu shadow position-fixed bg-white pt-3 menuitem-active overflow-auto custom-scroll-bar"
      id="sidebar"
    >
      <div
        class="d-flex align-items-center justify-content-center"
        (click)="collapseSide()"
      >
        <div class="cursor-pointer be-fulfilled" *ngIf="isForDashboard">
          <img src="../../assets/images/logo.svg" style="width: 10rem" />
        </div>

        <div class="cursor-pointer" *ngIf="!isForDashboard">
          <img src="../../assets/images/BF-logo.png" style="width: 3rem" />
        </div>
      </div>

      <div
        class="pt-3 d-flex align-items-start justify-content-center flex-column"
      >
        <div class="sidebar-sticky w-100 overflow-auto">
          <div
            class="cursor-pointer dashboard-active-span py-3"
            (click)="itemClicked()"
            routerLink="/dashboard"
            routerLinkActive="active"
            #rlaHome="routerLinkActive"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="pl-3 pr-2">
              <img
                src="../../assets/images/dashboard.svg"
                [class.d-none]="rlaHome.isActive"
              />
              <img
                src="../../assets/images/dashboard-active.svg"
                [class.d-none]="!rlaHome.isActive"
              />
            </span>
            <span class="fs-16 dashboard-label">Dashboard</span>
          </div>

          <div class="accordion" id="accordionExample">
            <div class="">
              <div
                class="cursor-pointer dashboard-active-span py-3 d-flex align-items-center justify-content-between pr-3"
                id="actionItems"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                (click)="actionItemsToggleCollapsed()"
                href="#actionItemsSubmenu"
                #rlaProfile="routerLinkActive"
                routerLinkActive="active"
                [ngClass]="{ active: action == true }"
              >
                <div class="d-flex align-items-center">
                  <div class="" (click)="actionItems()">
                    <span class="pl-3 pr-2" *ngIf="action == false">
                      <img
                        src="assets/images/actionItemsIcon.svg"
                        class="sidebar-icon"
                      />
                    </span>

                    <span class="pl-3 pr-2" *ngIf="action == true">
                      <img
                        src="assets/images/actionItemsSelectedIcon.svg"
                        class="sidebar-icon"
                      />
                    </span>
                  </div>
                  <span
                    data-toggle="collapse"
                    class="fs-16 cursor-pointer dashboard-label"
                    >Action Items</span
                  >
                </div>

                <!-- <div class=""> -->
                <img
                  src="assets/images/sidebarUpArrow.svg"
                  class="sidebar-up-arrow dashboard-label"
                  *ngIf="action"
                  (click)="actionItems()"
                />

                <img
                  src="assets/images/sidebarDownArrow.svg"
                  class="sidebar-down-arrow dashboard-label"
                  *ngIf="!action"
                  (click)="actionItems()"
                />
                <!-- </div> -->
              </div>

              <div
                id="collapseOne"
                class="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div
                  class="d-flex pl-5 ml-1 justify-content-center flex-column fs-16"
                  *ngIf="isForDashboard"
                >
                  <div
                    class="d-flex py-2 align-items-start py-2 dashboard-submenu cursor-pointer"
                    (click)="actionClicked()"
                    [routerLink]="['/notification/inbox/inbox-message']"
                    #rlaclient="routerLinkActive"
                    routerLinkActive="active"
                  >
                    My Messages
                  </div>

                  <div
                    class="d-flex py-2 align-items-start dashboard-submenu cursor-pointer"
                    (click)="actionClicked()"
                    [routerLink]="['/notification/task']"
                    #rlasupplier="routerLinkActive"
                    routerLinkActive="active"
                  >
                    My Tasks
                  </div>
                </div>
              </div>
            </div>

            <div class="">
              <div
                class="cursor-pointer dashboard-active-span py-3 d-flex align-items-center justify-content-between pr-3"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
                (click)="projectToggleCollapsed()"
                href="#projectsSubmenu"
                #rlaProfile="routerLinkActive"
                routerLinkActive="active"
                [ngClass]="{ active: projects == true }"
              >
                <div class="d-flex align-items-center">
                  <div class="" (click)="projectIcons()">
                    <span class="pl-3 pr-2" *ngIf="projects == false">
                      <img
                        src="assets/images/project.svg"
                        class="sidebar-icon"
                      />
                    </span>

                    <span class="pl-3 pr-2" *ngIf="projects == true">
                      <img
                        src="assets/images/project-active.svg"
                        class="sidebar-icon"
                      />
                    </span>
                  </div>
                  <span class="fs-16 dashboard-label">Projects</span>
                </div>

                <!-- <div class=""> -->
                <img
                  src="assets/images/sidebarUpArrow.svg"
                  class="sidebar-up-arrow dashboard-label"
                  *ngIf="projects"
                  (click)="projectIcons()"
                />

                <img
                  src="assets/images/sidebarDownArrow.svg"
                  class="sidebar-down-arrow dashboard-label"
                  *ngIf="!projects"
                  (click)="projectIcons()"
                />
                <!-- </div> -->
              </div>
              <div
                id="collapseTwo"
                class="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionExample"
              >
                <div *ngIf="isForDashboard" id="projectsSubmenu">
                  <div class="" (click)="createNewProject()">
                    <button
                      type="button"
                      class="btn align-items-center create-new-project m-2"
                    >
                      <img
                        src="assets/images/add-projet.svg"
                        style="width: 1rem"
                        class="mr-1"
                      />

                      <span class="fs-16 color-faint-orange"
                        >Create New Project</span
                      >
                    </button>
                  </div>

                  <div
                    class="pl-5 project-list-view maxMain custom-scroll-bar create-project"
                  >
                    <div
                      class="py-2 fs-16 dashboard-submenu cursor-pointer"
                      #rlaclient="routerLinkActive"
                      [routerLinkActive]="['active']"
                      (click)="
                        projectsClicked(rlaclient, item?.company_name);
                        navigateToProject(item)
                      "
                      *ngFor="let item of clientData"
                      [routerLink]="'/project/' + item?._id + projectRoutes"
                    >
                      <!-- [routerLink]="'/project/' + item?._id + '/task'" -->
                      {{ item?.company_name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="">
              <div class="" id="headingThree">
                <div
                  class="cursor-pointer dashboard-active-span py-3 d-flex align-items-center justify-content-between pr-3"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                  (click)="profileToggleCollapsed()"
                  href="#profilesSubmenu"
                  #rlaProfile="routerLinkActive"
                  routerLinkActive="active"
                  [ngClass]="{ active: profile == true }"
                >
                  <div class="d-flex align-items-center">
                    <div (click)="profileIcons()">
                      <span class="pl-3 pr-2" *ngIf="profile == false">
                        <img
                          src="assets/images/user.svg"
                          class="sidebar-icon"
                        />
                      </span>

                      <span class="pl-3 pr-2" *ngIf="profile == true">
                        <img
                          src="assets/images/user-active.svg"
                          class="sidebar-icon"
                        />
                      </span>
                    </div>
                    <span class="fs-16 dashboard-label">Profiles</span>
                  </div>

                  <!-- <div class="" > -->
                  <img
                    src="assets/images/sidebarUpArrow.svg"
                    class="sidebar-up-arrow dashboard-label"
                    *ngIf="profile"
                    (click)="profileIcons()"
                  />

                  <img
                    src="assets/images/sidebarDownArrow.svg"
                    class="sidebar-down-arrow dashboard-label"
                    *ngIf="!profile"
                    (click)="profileIcons()"
                  />
                  <!-- </div> -->
                </div>
              </div>
              <div
                id="collapseThree"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div class="pl-5" id="profilesSubmenu" *ngIf="isForDashboard">
                  <div
                    class="py-2 fs-16 dashboard-submenu cursor-pointer d-flex align-items-center justify-content-between"
                    (click)="profileClicked()"
                    [routerLink]="['profile/customers']"
                    #rlaclient="routerLinkActive"
                    routerLinkActive="active"
                  >
                    <span>Customer</span>
                    <div
                      class="add-client-supplier rounded mr-2"
                      (click)="addNewClient($event)"
                      [routerLink]="['profile/customers']"
                    >
                      <img src="assets/images/add-projet.svg" class="" />
                    </div>
                  </div>

                  <div
                    class="fs-16 py-2 dashboard-submenu cursor-pointer d-flex align-items-center justify-content-between"
                    (click)="profileClicked()"
                    [routerLink]="'/profile/supplier'"
                    #rlasupplier="routerLinkActive"
                    routerLinkActive="active"
                  >
                    <span>Supplier</span>
                    <div
                      class="add-client-supplier rounded mr-2"
                      (click)="addNewSupplier($event)"
                      [routerLink]="'/profile/supplier'"
                    >
                      <img src="assets/images/add-projet.svg" class="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              (click)="itemClicked()"
              class="dashboard-active-span py-3 cursor-pointer"
              [routerLink]="['/setting/general']"
              routerLinkActive="active"
              #rlaSettings="routerLinkActive"
            >
              <span class="pl-3 pr-2">
                <img
                  class="sidebar-icon"
                  [class.d-none]="rlaSettings.isActive"
                  src="../../assets/images/setting.svg"
                />

                <img
                  class="sidebar-icon"
                  [class.d-none]="!rlaSettings.isActive"
                  src="../../assets/images/settings-active.svg"
                />
              </span>
              <span class="fs-16 dashboard-label">Settings</span>
            </div>

            <div class="mb-5">
              <div
                class="cursor-pointer dashboard-active-span py-3 d-flex align-items-center justify-content-between pr-3"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
                (click)="adminItemsToggleCollapsed()"
                href="#adminSubmenu"
                #rlaadmin="routerLinkActive"
                routerLinkActive="active"
                *ngIf="Auth.checkPermssions('admin_setting', null)"
                [ngClass]="{ active: admin == true }"
              >
                <div class="d-flex align-items-center">
                  <div class="" (click)="adminItems()">
                    <span class="pl-3 pr-2">
                      <img
                        class="sidebar-icon"
                        [class.d-none]="rlaadmin.isActive"
                        src="../../../assets/images/admin-settings-icon.svg"
                      />

                      <img
                        class="sidebar-icon"
                        [class.d-none]="!rlaadmin.isActive"
                        src="../../../assets/images/admin-setting.svg"
                      />
                    </span>
                  </div>
                  <span
                    data-toggle="collapse"
                    class="fs-16 cursor-pointer dashboard-label"
                    >Admin</span
                  >
                </div>

                <!-- <div class=""> -->
                <img
                  src="assets/images/sidebarUpArrow.svg"
                  class="sidebar-up-arrow dashboard-label"
                  *ngIf="admin"
                  (click)="adminItems()"
                />

                <img
                  src="assets/images/sidebarDownArrow.svg"
                  class="sidebar-down-arrow dashboard-label"
                  *ngIf="!admin"
                  (click)="adminItems()"
                />
                <!-- </div> -->
              </div>

              <div
                id="collapseFour"
                class="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div
                  class="d-flex pl-5 ml-1 justify-content-center flex-column fs-16"
                  *ngIf="isForDashboard"
                >
                  <div
                    class="d-flex py-2 align-items-start py-2 dashboard-submenu cursor-pointer"
                    [routerLink]="['/setting/admin']"
                    (click)="adminClicked()"
                    #rlaclient="routerLinkActive"
                    routerLinkActive="active"
                  >
                    Settings
                  </div>
                  <div
                    class="d-flex py-2 align-items-start py-2 dashboard-submenu cursor-pointer"
                    [routerLink]="['/help/help-document']"
                    (click)="adminClicked()"
                    #rlaclient="routerLinkActive"
                    routerLinkActive="active"
                  >
                    Help Document
                  </div>

                  <div
                    class="d-flex py-2 align-items-start dashboard-submenu cursor-pointer"
                    [routerLink]="['/customer/customer-archive']"
                    (click)="adminClicked()"
                    #rlasupplier="routerLinkActive"
                    routerLinkActive="active"
                  >
                    Customer Archive
                  </div>
                  <div
                    class="d-flex py-2 align-items-start dashboard-submenu cursor-pointer"
                    [routerLink]="['/supplier/supplier-archive']"
                    (click)="adminClicked()"
                    #rlasupplier="routerLinkActive"
                    routerLinkActive="active"
                  >
                    Supplier Archive
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div
            class="dashboard-active-span py-3 cursor-pointer"
            (click)="itemClicked()"
            [routerLink]="['/setting/admin']"
            routerLinkActive="active"
            #rlaadmin="routerLinkActive"
            *ngIf="Auth.checkPermssions('admin_setting', null)"
          >
            <span class="pl-3 pr-2">
              <img
                class="sidebar-icon"
                [class.d-none]="rlaadmin.isActive"
                src="../../../assets/images/admin-settings-icon.svg"
              />

              <img
                class="sidebar-icon"
                [class.d-none]="!rlaadmin.isActive"
                src="../../../assets/images/admin-setting.svg"
              />
            </span>
            <span class="fs-16 dashboard-label">Admin Settings</span>
          </div> -->
        </div>
      </div>
    </div>

    <div class="content-page" id="routeContent">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div
              class="d-flex align-items-center justify-content-end navbar-items"
            >
              <div class="dropdown">
                <div
                  class="btn dropdown-toggle rounded-circle mr-3 position-relative cursor-pointer shadow px-2 py-1"
                  type="button"
                  id="dropdownNotification"
                  data-toggle="dropdown"
                  (click)="readNotify()"
                >
                  <div
                    class="rounded-circle notification-dot position-absolute"
                    *ngIf="showNotification()"
                  ></div>
                  <img
                    src="../../assets/images/bell.svg"
                    style="width: 1.06rem"
                  />
                </div>
                <div
                  class="dropdown-menu mt-3 pb-0 notification-container dropdown-menu-right bg-white border-0 shadow rounded"
                  aria-labelledby="dropdownNotification"
                >
                  <div class="px-3">
                    <div class="header-Notification">
                      <span class="color-secondary fs-20 font-bold"
                        >Notifications</span
                      >
                    </div>
                    <div class="containe-scroll custom-scroll-bar">
                      <div
                        class="d-flex justify-content-between position-relative notifc-border py-2"
                        *ngFor="let item of inboxItem"
                        (click)="readNotification()"
                      >
                        <div
                          class="rounded-circle ringnotific-dot position-absolute"
                          *ngIf="!item.isRead"
                        ></div>
                        <img
                          class="rounded-circle userImage mr-2 cursor-pointer"
                          [src]="
                            displayImage
                              ? displayImage
                              : '../../assets/images/face8.jpg'
                          "
                          style="width: 1.5rem; height: 1.5rem"
                        />
                        <span
                          class="fs-13 cursor-pointer flex-fill links-text"
                          *ngIf="item?.comment"
                        >
                          {{ item?.sender_id?.name }} tagged you in a comment
                        </span>
                        <span
                          class="fs-13 cursor-pointer flex-fill links-text"
                          *ngIf="item?.task"
                        >
                          {{ item?.sender_id?.name }} assigned a task to
                          you</span
                        >
                        <span
                          class="fs-13 cursor-pointer flex-fill links-text"
                          *ngIf="item?.project && !item?.summary"
                        >
                          {{ item?.sender_id?.name }} assigned a project to
                          you</span
                        >
                        <span
                          class="fs-13 cursor-pointer flex-fill links-text"
                          *ngIf="item?.summary"
                        >
                          {{ item?.sender_id?.name }} created new updates
                        </span>
                        <!-- <span class="fs-13 cursor-pointer color-title"
                          >-49 min</span
                        > -->
                        <!-- <span
                          class="fs-13 cursor-pointer color-title"
                          *ngIf="item[0] === latest_date"
                          >Today</span
                        >
                        <span
                          class="fs-13 cursor-pointer color-title"
                          *ngIf="item[0] === yes_date"
                          >Yesterday</span
                        > -->
                        <!-- <span
                          class="fs-13 cursor-pointer color-title"
                          *ngIf="
                            item[0] !== latest_date && item[0] !== yes_date
                          "
                        >
                          {{ item[0] | daysAgo }}
                        </span> -->
                        <span class="fs-13 cursor-pointer color-title">{{
                          item?.createdAt | daysAgo
                        }}</span>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end py-2">
                      <span
                        class="color-orange-dark cursor-pointer fs-18 fw-600"
                        >Clear All</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex align-items-center fw-600 mr-2 fs-16">
                <span class="mr-2">Hi,</span>
                <span>{{ userName ? userName : "" }}</span>
              </div>

              <div class="dropdown dropdown-menu-right">
                <button
                  class="btn dropdown-toggle rounded-circle user-profile-image cursor-pointer p-0"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                >
                  <img
                    class="rounded-circle userImage"
                    [src]="userImage"
                    style="width: 2rem; height: 2rem"
                  />
                </button>
                <div
                  class="dropdown-menu dropdown-menu-right drop-shadow"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a class="dropdown-item" [routerLink]="['/setting/general']"
                    >Edit User</a
                  >
                  <a (click)="logout()" class="dropdown-item" routerLink="/auth"
                    >Logout</a
                  >
                </div>

                <div
                  class="active-dot bg-white rounded-circle position-absolute"
                >
                  <div class="rounded-circle active-user-dot"></div>
                </div>
              </div>
            </div>

            <!-- <router-outlet></router-outlet> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <ng-template *ngIf="projectLazy" [ngComponentOutlet]="projectLazy | async">
  </ng-template> -->
  <!-- <ng-template *ngIf="clientLazy" [ngComponentOutlet]="clientLazy | async">
  </ng-template> -->
</div>

<div *ngIf="!Auth.getUserRole()">
  <div class="bg-light">
    <div
      class="leftside-menu shadow position-fixed bg-white pt-3 menuitem-active overflow-auto custom-scroll-bar"
      id="sidebar"
    >
      <div
        class="d-flex align-items-center justify-content-center"
        (click)="collapseSide()"
      >
        <div class="cursor-pointer be-fulfilled" *ngIf="isForDashboard">
          <img src="../../assets/images/logo.svg" style="width: 10rem" />
        </div>

        <div class="cursor-pointer" *ngIf="!isForDashboard">
          <img src="../../assets/images/logo-sm.png" style="width: 4rem" />
        </div>
      </div>

      <div
        class="pt-3 d-flex align-items-start justify-content-center flex-column"
      >
        <div class="sidebar-sticky w-100 overflow-auto">
          <div
            class="cursor-pointer dashboard-active-span py-3"
            (click)="itemClicked()"
            [routerLink]="['/dashboard']"
            routerLinkActive="active"
            #rlaHome="routerLinkActive"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="pl-3 pr-2">
              <img
                src="../../assets/images/dashboard.svg"
                [class.d-none]="rlaHome.isActive"
              />
              <img
                src="../../assets/images/dashboard-active.svg"
                [class.d-none]="!rlaHome.isActive"
              />
            </span>
            <span class="fs-16 dashboard-label">Dashboard</span>
          </div>

          <div class="accordion" id="accordionExample">
            <div class="">
              <div
                class="cursor-pointer dashboard-active-span py-3 d-flex align-items-center justify-content-between pr-3"
                id="actionItems"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                (click)="actionItemsToggleCollapsed()"
                href="#actionItemsSubmenu"
                #rlaProfile="routerLinkActive"
                routerLinkActive="active"
                [ngClass]="{ active: action == true }"
              >
                <div class="d-flex align-items-center">
                  <div class="" (click)="actionItems()">
                    <span class="pl-3 pr-2" *ngIf="action == false">
                      <img
                        src="assets/images/actionItemsIcon.svg"
                        class="sidebar-icon"
                      />
                    </span>

                    <span class="pl-3 pr-2" *ngIf="action == true">
                      <img
                        src="assets/images/actionItemsSelectedIcon.svg"
                        class="sidebar-icon"
                      />
                    </span>
                  </div>
                  <span
                    data-toggle="collapse"
                    class="fs-16 cursor-pointer dashboard-label"
                    >Action Items</span
                  >
                </div>

                <!-- <div class=""> -->
                <img
                  src="assets/images/sidebarUpArrow.svg"
                  class="sidebar-up-arrow dashboard-label"
                  *ngIf="action == true"
                />

                <img
                  src="assets/images/sidebarDownArrow.svg"
                  class="sidebar-down-arrow dashboard-label"
                  *ngIf="action == false"
                />
                <!-- </div> -->
              </div>

              <div
                id="collapseOne"
                class="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div
                  class="d-flex pl-5 ml-1 justify-content-center flex-column fs-16"
                  *ngIf="isForDashboard"
                >
                  <div
                    class="d-flex py-2 align-items-start py-2 dashboard-submenu cursor-pointer"
                    (click)="actionClicked()"
                    [routerLink]="['/notification//inbox/inbox-message']"
                    #rlaclient="routerLinkActive"
                    routerLinkActive="active"
                  >
                    My Messages
                  </div>

                  <div
                    class="d-flex py-2 align-items-start dashboard-submenu cursor-pointer"
                    (click)="actionClicked()"
                    [routerLink]="['/notification/task']"
                    #rlasupplier="routerLinkActive"
                    routerLinkActive="active"
                  >
                    My Tasks
                  </div>
                </div>
              </div>
            </div>

            <div class="">
              <div
                class="cursor-pointer dashboard-active-span py-3 d-flex align-items-center justify-content-between pr-3"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
                (click)="projectToggleCollapsed1()"
                href="#projectsSubmenu"
                [routerLink]="'/project/' + this.clientID + '/task'"
                #rlaProfile="routerLinkActive"
                routerLinkActive="active"
                [ngClass]="{ active: projects == true }"
              >
                <div class="d-flex align-items-center">
                  <div class="" (click)="projectIcons()">
                    <span class="pl-3 pr-2" *ngIf="projects == false">
                      <img
                        src="assets/images/project.svg"
                        class="sidebar-icon"
                      />
                    </span>

                    <span class="pl-3 pr-2" *ngIf="projects == true">
                      <img
                        src="assets/images/project-active.svg"
                        class="sidebar-icon"
                      />
                    </span>
                  </div>
                  <span class="fs-16 dashboard-label">Projects</span>
                </div>

                <!-- <div class=""> -->
                <img
                  class="sidebar-up-arrow dashboard-label"
                  *ngIf="projects"
                  (click)="projectIcons()"
                />

                <img
                  class="sidebar-down-arrow dashboard-label"
                  *ngIf="!projects"
                  (click)="projectIcons()"
                />
                <!-- </div> -->
              </div>
            </div>

            <div class="">
              <div class="" id="headingThree">
                <div
                  class="cursor-pointer dashboard-active-span py-3 d-flex align-items-center justify-content-between pr-3"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                  [routerLink]="['/setting/general']"
                  routerLinkActive="active"
                >
                  <div
                    class="d-flex align-items-center"
                    (click)="itemClicked()"
                  >
                    <div>
                      <span class="pl-3 pr-2" *ngIf="profile == false">
                        <img
                          src="assets/images/setting.svg"
                          class="sidebar-icon"
                        />
                      </span>

                      <span class="pl-3 pr-2" *ngIf="profile == true">
                        <img
                          src="assets/images/setting-active.svg"
                          class="sidebar-icon"
                        />
                      </span>
                    </div>
                    <span class="fs-16 dashboard-label">Settings</span>
                  </div>

                  <!-- <div class="" > -->
                  <!-- <img
                    src="assets/images/sidebarUpArrow.svg"
                    class="sidebar-up-arrow dashboard-label"
                    *ngIf="profile"
                  />
  
                  <img
                    src="assets/images/sidebarDownArrow.svg"
                    class="sidebar-down-arrow dashboard-label"
                    *ngIf="!profile"
                  /> -->
                  <!-- </div> -->
                </div>
              </div>
            </div>

            <div
              class="dashboard-active-span py-3 cursor-pointer"
              (click)="itemClicked()"
              [routerLink]="['/setting/admin']"
              routerLinkActive="active"
              #rlaadmin="routerLinkActive"
              *ngIf="Auth.checkPermssions('admin_setting', null)"
            >
              <!-- role.role == 'client' -->
              <span class="pl-3 pr-2">
                <img
                  class="sidebar-icon"
                  [class.d-none]="rlaadmin.isActive"
                  src="../../../assets/images/admin-settings-icon.svg"
                />

                <img
                  class="sidebar-icon"
                  [class.d-none]="!rlaadmin.isActive"
                  src="../../../assets/images/admin-setting.svg"
                />
              </span>
              <span class="fs-16 dashboard-label">Admin Settings</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-page" id="routeContent">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div
              class="d-flex align-items-center justify-content-between flex-wrap mt-2"
            >
              <div class="d-flex align-items-start">
                <img
                  src="../../assets/images/user-image.svg"
                  style="width: 1.9rem"
                />

                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <span class="fs-14 fw-700 ml-2">Hillary Hewitt</span>
                    <span class="fs-12 ml-1 color-title-light"
                      >( Project Manager )</span
                    >
                  </div>
                  <div class="d-flex align-items-center fs-12">
                    <span class="ml-2 mb-1"
                      ><img
                        src="../../assets/images/mail.svg"
                        style="width: 0.7rem"
                    /></span>
                    <span class="color-title-light ml-2"
                      >hillary@befulfilled.net</span
                    >

                    <span class="ml-5 mb-1"
                      ><img
                        src="../../assets/images/call.svg"
                        style="width: 0.6rem"
                    /></span>
                    <span class="color-title-light ml-2">(562) 243-7376</span>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <span class="fs-16 color-dark fw-700 mr-3"
                  >“Some women fear the fire, some women simply become it.” -
                  R.H. Sin</span
                >
                <div class="dropdown">
                  <div
                    class="btn dropdown-toggle rounded-circle mr-3 position-relative cursor-pointer shadow px-2 py-1"
                    type="button"
                    id="dropdownNotification"
                    data-toggle="dropdown"
                  >
                    <div
                      class="rounded-circle notification-dot position-absolute"
                    ></div>
                    <img
                      src="../../assets/images/bell.svg"
                      style="width: 1.06rem"
                    />
                  </div>
                  <div
                    class="dropdown-menu mt-3 pb-0 notification-container dropdown-menu-right bg-white border-0 shadow rounded"
                    aria-labelledby="dropdownNotification"
                  >
                    <div class="px-3">
                      <div class="header-Notification">
                        <span class="color-secondary fs-20 font-bold"
                          >Notifications</span
                        >
                      </div>
                      <div class="containe-scroll custom-scroll-bar">
                        <div
                          class="d-flex justify-content-between position-relative notifc-border py-2"
                          *ngFor="let item of inboxItem"
                        >
                          <div
                            class="rounded-circle ringnotific-dot position-absolute"
                          ></div>
                          <img
                            class="rounded-circle userImage mr-2 cursor-pointer"
                            [src]="
                              displayImage
                                ? displayImage
                                : '../../assets/images/face8.jpg'
                            "
                            style="width: 1.5rem; height: 1.5rem"
                          />
                          <span
                            class="fs-13 cursor-pointer flex-fill links-text"
                            *ngIf="item?.comment"
                          >
                            {{ item?.comment?.message }}
                          </span>
                          <span
                            class="fs-13 cursor-pointer flex-fill links-text"
                            *ngIf="item?.task"
                          >
                            {{ item?.sender_id?.name }} assigned a task to
                            you</span
                          >
                          <span
                            class="fs-13 cursor-pointer flex-fill links-text"
                            *ngIf="item?.project"
                          >
                            {{ item?.sender_id?.name }} assigned a project to
                            you</span
                          >
                          <!-- <span class="fs-13 cursor-pointer color-title"
                            >-49 min</span
                          > -->
                          <!-- <span
                            class="fs-13 cursor-pointer color-title"
                            *ngIf="item[0] === latest_date"
                            >Today</span
                          >
                          <span
                            class="fs-13 cursor-pointer color-title"
                            *ngIf="item[0] === yes_date"
                            >Yesterday</span
                          > -->
                          <!-- <span
                            class="fs-13 cursor-pointer color-title"
                            *ngIf="
                              item[0] !== latest_date && item[0] !== yes_date
                            "
                          >
                            {{ item[0] | daysAgo }}
                          </span> -->
                          <span class="fs-13 cursor-pointer color-title">{{
                            item?.createdAt | daysAgo
                          }}</span>
                        </div>
                      </div>
                      <div class="d-flex justify-content-end py-2">
                        <span
                          class="color-orange-dark cursor-pointer fs-18 fw-600"
                          >Clear All</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex align-items-center fw-600 mr-2 fs-16">
                  <span class="mr-2">Hi,</span>
                  <span *ngIf="role.role == 'client'">{{
                    clientName ? clientName : ""
                  }}</span>
                  <span *ngIf="role.role == 'user'">{{
                    userName ? userName : ""
                  }}</span>
                </div>

                <div class="dropdown dropdown-menu-right">
                  <button
                    class="btn dropdown-toggle rounded-circle user-profile-image cursor-pointer p-0"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                  >
                    <img
                      class="rounded-circle userImage"
                      style="width: 2rem; height: 2rem"
                      [src]="displayImage"
                    />
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a class="dropdown-item" [routerLink]="['/setting/general']"
                      >Edit User</a
                    >
                    <a
                      (click)="logout()"
                      class="dropdown-item"
                      routerLink="/auth"
                      >Logout</a
                    >
                  </div>

                  <div
                    class="active-dot bg-white rounded-circle position-absolute"
                  >
                    <div class="rounded-circle active-user-dot"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <router-outlet></router-outlet> -->
    </div>
  </div>
  <ng-template *ngIf="projectLazy" [ngComponentOutlet]="projectLazy | async">
  </ng-template>
</div>
