/*
    creating navigation in this file 
    also added lazy loading concept 
    below with loadchildren properties
*/
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { ForgetPasswordComponent } from './common-components/forget-password/forget-password.component';
import { PageNotFoundComponent } from './common-components/page-not-found/page-not-found.component';
import { ResetPasswordComponent } from './common-components/reset-password/reset-password.component';
import { SharedModule } from './common-modules/shared.module';
import { HomeComponent } from './components/dashboard/home/home.component';

const routes: Routes = [
  // blank redirection
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' }, // blank router redirected to dashboard
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  }, // auth redirect..

  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
  },

  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./components/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        canActivate: [AuthGuard],
      }, // dashboard redirect..
      {
        path: 'notification',
        loadChildren: () =>
          import('./components/notification/notification.module').then(
            (m) => m.NotificationModule
          ),
        canActivate: [AuthGuard],
      }, // notification redirect..
      {
        path: 'profile/customers',
        loadChildren: () =>
          import('./components/profile/client/client.module').then(
            (m) => m.ClientModule
          ),
        canActivate: [AuthGuard],
      }, // profile redirect..
      {
        path: 'profile/supplier',
        loadChildren: () =>
          import('./components/profile/supplier/supplier.module').then(
            (m) => m.SupplierModule
          ),
        canActivate: [AuthGuard],
      }, // profile redirect..
      {
        path: 'project',
        loadChildren: () =>
          import('./components/project/project.module').then(
            (m) => m.ProjectModule
          ),
        canActivate: [AuthGuard],
      }, // project redirect..
      {
        path: 'setting',
        loadChildren: () =>
          import('./components/setting/setting.module').then(
            (m) => m.SettingModule
          ),
        canActivate: [AuthGuard],
      }, // setting redirect..
      {
        path: 'customer',
        loadChildren: () =>
          import(
            './components/setting/archive/customer-archive/customer-archive.module'
          ).then((m) => m.CustomerArchiveModule),
        canActivate: [AuthGuard],
      }, // setting redirect..
      {
        path: 'supplier',
        loadChildren: () =>
          import(
            './components/setting/archive/supplier-archive/supplier-archive.module'
          ).then((m) => m.SupplierArchiveModule),
        canActivate: [AuthGuard],
      }, // setting redirect..
      {
        path: 'help',
        loadChildren: () =>
          import('./components/setting/help/help.module').then(
            (m) => m.HelpModule
          ),
        canActivate: [AuthGuard],
      }, // setting redirect..
    ],
  },

  // Wildcard route if url doesnt match with routes..
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), SharedModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
