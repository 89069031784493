<div class="bg-light">
  <app-left-menu></app-left-menu>

  <div class="content-page" id="routeContent">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <router-outlet></router-outlet>
          <!-- <h1 style="text-align: center;">Hello world </h1> -->
        </div>
      </div>
    </div>
  </div>
</div>
