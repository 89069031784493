<div class="modal-header">
  <div class="pl-2 fw-700 fs-22" *ngIf="!isEditProject">Create New Project</div>
  <div class="pl-2 fw-700 fs-22" *ngIf="isEditProject">Edit Project</div>
</div>
<div class="modal-body px-4">
  <form
    autocomplete="off"
    #createProjectForm="ngForm"
    (submit)="
      isEditProject
        ? createProjectForm.valid && editProject(createProjectForm)
        : createProjectForm.valid && createProject(createProjectForm)
    "
  >
    <div class="form-row">
      <div class="form-group col-6">
        <label for="technical" class="fs-16 mb-0">
          <span class="text-danger">*</span>Template
        </label>
        <div *ngIf="isEditProject">
          <input
            type="text"
            name=""
            id="technical"
            class="form-control fs-18 fw-500"
            value=" {{ initialProject?.template?.template_name }}"
            readonly
          />
        </div>

        <div class="dropdown" *ngIf="!isEditProject">
          <div
            type="text"
            class="form-control fs-18"
            id="technical"
            name="template"
            required
            class="btn dropdown-toggle d-flex border justify-content-between filter-opt6 dropdown1"
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
            ngDefaultControl
          >
            <span
              class="filter6 fs-18 fw-500 text-truncate"
              id="selectTemplate"
            >
              <div
                *ngIf="!isEditProject"
                class="edit-project-dropdown fw-500 input-Height"
              >
                {{ this.templateValue }}
              </div>

              <div class="fw-600 text-dark" *ngIf="isEditProject">
                {{ initialProject?.template?.template_name }}
              </div>
            </span>
            <img src="assets/images/Path 61663.svg" class="order-1" />
          </div>
          <div
            class="dropdown-menu dropdown-menu-right filter-list5 border-0 shadow dropdown cursor-pointer custom-scroll-bar dropdown-list w-100"
            aria-labelledby="selectTemplate"
          >
            <a
              class="dropdown-item hoverName"
              *ngFor="let x of TemplateList"
              (click)="templateDropDown($event, x)"
              ><span>{{ x.template_name }}</span></a
            >
          </div>
        </div>
        <div class="invalid-feedback d-flex" *ngIf="templateError">
          Please select template.
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-6">
        <label for="companyName" class="fs-16 mb-0">
          <span class="text-danger">*</span>Company Name
        </label>
        <div class="dropdown" *ngIf="!isEditProject">
          <div
            class="btn dropdown-toggle d-flex fs-18 justify-content-between filter-opt1 dropdown1 border rounded"
            type="button"
            id="companyName"
            data-toggle="dropdown"
          >
            <span class="fs-18 fw-500 text-truncate">Company Name</span>
            <img src="assets/images/Path 61663.svg" class="order-1" />
          </div>
          <div
            class="dropdown-menu dropdown-menu-right filter-list2 border-0 shadow dropdown cursor-pointer w-100 dropdown-list custom-scroll-bar"
          >
            <a
              class="dropdown-item hoverName"
              *ngFor="let p of clientData"
              (click)="companyNameDropdown($event, p)"
              ><span>{{ p?.company_name }}</span></a
            >
          </div>
          <div class="invalid-feedback d-flex" *ngIf="companyNameError">
            Company Name is required.
          </div>
        </div>

        <div
          class="py-3 d-flex align-items-center bg-light form-control"
          disabled
          *ngIf="isEditProject"
        >
          {{ initialProject?.company_name?.company_name }}
        </div>
      </div>

      <div class="form-group col-6">
        <label for="clientContact" class="fs-16 mb-0"
          ><span class="text-danger">*</span>Primary Customer Contact</label
        >
        <input
          type="text"
          class="form-control py-3 fs-18"
          minlength="10"
          maxlength="10"
          id="clientContact"
          [(ngModel)]="clientOverview"
          disabled
          name="clientContact"
          ngModel
          #ClientContact="ngModel"
          required
        />
      </div>

      <div class="form-group col-6" *ngIf="!isEditProject">
        <label for="projectName" class="fs-16 mb-0">
          <span class="text-danger">*</span>Project Name</label
        >
        <input
          type="text"
          class="form-control fs-18"
          id="projectName"
          [(ngModel)]="projectName"
          (keyup)="enableSave()"
          name="projectName"
          ngModel
          #ProjectName="ngModel"
          placeholder="Project Name"
          required
        />
        <div
          *ngIf="
            ProjectName.invalid &&
            ProjectName.errors?.required &&
            (createProjectForm.submitted ||
              ProjectName.dirty ||
              ProjectName.touched)
          "
          class="invalid-feedback d-flex"
        >
          Project Name is required.
        </div>
      </div>
      <div class="form-group col-6" *ngIf="isEditProject">
        <label for="projectName" class="fs-16 mb-0">Project Name</label>
        <input
          type="text"
          class="form-control fs-18"
          id="projectName"
          [(ngModel)]="initialProject.name"
          (keyup)="enableSave()"
          name="projectName"
          ngModel
          #ProjectName="ngModel"
          placeholder="Project Name"
          required
        />
      </div>

      <div class="form-group col-12">
        <label for="projectDescription" class="fs-16 mb-0"
          ><span class="text-danger">*</span>Project Description</label
        >
        <textarea
          name="ProjectDescription"
          class="form-control py-3 fs-18"
          rows="3"
          id="projectDescription"
          placeholder="Enter Description"
          [(ngModel)]="initialProject.project_description"
          (keyup)="enableSave()"
          ngModel
          #ProjectDescription="ngModel"
          required
        >
        </textarea>
        <div
          *ngIf="
            ProjectDescription.invalid &&
            ProjectDescription.errors?.required &&
            (createProjectForm.submitted ||
              ProjectDescription.dirty ||
              ProjectDescription.touched)
          "
          class="invalid-feedback d-flex"
        >
          Please enter a message in the textarea.
        </div>
      </div>

      <div class="form-group col-5">
        <label for="datepicker " class="fs-16 mb-0 datepicker-input"
          ><span class="text-danger">*</span>Release Date</label
        >
        <div class="form-group rounded input-date" *ngIf="!isEditProject">
          <input
            class="dateimage form-control cursor-pointer fs-18"
            type="text"
            id="datepicker"
            autocomplete="off"
            [minDate]="minDate"
            bsDatepicker
            [bsConfig]="{
              dateInputFormat: 'MM-DD-YYYY',
              showWeekNumbers: false,
              showClearButton: true
            }"
            [(ngModel)]="projectDueDate"
            (ngModelChange)="enableSave()"
            #bsDatepicker="ngModel"
            name="datepicker"
            placeholder="Choose Release Date"
            required
          />
          <div
            *ngIf="
              bsDatepicker.invalid &&
              bsDatepicker.errors?.required &&
              (createProjectForm.submitted ||
                bsDatepicker.dirty ||
                bsDatepicker.touched)
            "
            class="invalid-feedback d-flex"
          >
            Please enter a Release Date.
          </div>
        </div>
        <div class="rounded input-date" *ngIf="isEditProject">
          <input
            class="dateimage form-control font-bold cursor-pointer fs-18"
            type="text"
            id="datepicker"
            autocomplete="off"
            [minDate]="minDate"
            bsDatepicker
            [bsConfig]="{
              dateInputFormat: 'MM-DD-YYYY',
              showWeekNumbers: false
            }"
            [(ngModel)]="initialProject.due_date"
            name="datepicker"
            #bsDatepicker="ngModel"
            (ngModelChange)="enableSave()"
            required
          />
          <div
            *ngIf="
              bsDatepicker.invalid &&
              bsDatepicker.errors?.required &&
              (createProjectForm.submitted ||
                bsDatepicker.dirty ||
                bsDatepicker.touched)
            "
            class="invalid-feedback d-flex"
          >
            Please enter a Release Date.
          </div>
          <div
            *ngIf="
              createProjectForm.submitted && initialProject.due_date < minDate
            "
            class="invalid-feedback d-flex"
          >
            Due date cannot be before the current date
          </div>
        </div>
      </div>

      <div class="col-12 fw-600 fs-18 mb-2 mt-3">Project Team Players</div>

      <div class="form-group col-6">
        <label for="accountManger" class="fs-16 mb-0"> Account Manager </label>

        <div class="dropdown">
          <div
            class="btn dropdown-toggle d-flex border justify-content-between filter-opt2 dropdown1"
            required
            type="button"
            name="accountManger"
            ngDefaultControl
            data-toggle="dropdown"
            aria-expanded="false"
            id="accountManger"
          >
            <span
              class="filter2 fs-18 text-dark fw-500 text-truncate"
              id="selectAccounter"
            >
              <div *ngIf="!isEditProject" class="edit-project-dropdown fw-500">
                Account Manager Name
              </div>

              <div class="fw-600 text-dark" *ngIf="isEditProject">
                {{ initialProject?.account_manager?.name }}
              </div>
            </span>
            <img src="assets/images/Path 61663.svg" class="order-1" />
          </div>
          <div
            class="dropdown-menu dropdown-menu-right filter-list2 border-0 shadow dropdown cursor-pointer w-100 dropdown-list custom-scroll-bar"
            aria-labelledby="selectAccounter"
          >
            <input
              id="searchAccount"
              name="searchAccount"
              type="text"
              class="fs-14 form-control"
              placeholder="Search Account Manager"
              [(ngModel)]="searchAccountValue"
              (ngModelChange)="searchAccountManager($event)"
            />
            <a
              class="dropdown-item hoverName"
              *ngFor="let x of userList"
              (click)="accountManagerDropdown($event, x)"
              ><span class="">{{ x?.name + " " + x?.last_name }}</span></a
            >
          </div>
          <!-- <div class="invalid-feedback d-flex" *ngIf="accountMangerError">
              Account Manager Name is required.
            </div> -->
        </div>
      </div>

      <div class="form-group col-6">
        <label for="productManager" class="fs-16 mb-0"> Product Manager </label>

        <div class="dropdown">
          <div
            type="button"
            name="productManager"
            id="productManager"
            ngDefaultControl
            class="btn dropdown-toggle d-flex border justify-content-between filter-opt3 dropdown1"
            required
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <span class="filter3 fs-18 fw-500 text-truncate" id="selectProduct">
              <div *ngIf="!isEditProject" class="edit-project-dropdown fw-500">
                Product Manager Name
              </div>

              <div class="fw-600 text-dark" *ngIf="isEditProject">
                {{ initialProject?.product_manager?.name }}
              </div>
            </span>
            <img src="assets/images/Path 61663.svg" class="order-1" />
          </div>
          <div
            class="dropdown-menu dropdown-menu-right filter-list3 border-0 shadow dropdown cursor-pointer dropdown-list custom-scroll-bar w-100"
            aria-labelledby="selectAccounter"
          >
            <input
              id="searchProduct"
              name="searchProduct"
              type="text"
              class="fs-14 form-control"
              placeholder="Search Product Manager"
              [(ngModel)]="searchProductValue"
              (ngModelChange)="searchProductManager($event)"
            />
            <a
              class="dropdown-item hoverName"
              *ngFor="let x of userList"
              (click)="productManagerDropdown($event, x)"
              ><span>{{ x?.name + " " + x?.last_name }}</span></a
            >
          </div>
          <!-- <div class="invalid-feedback d-flex" *ngIf="productManagerError">
              product Manager Name is required.
            </div> -->
        </div>
      </div>

      <div class="form-group col-6">
        <label for="leadManager" class="fs-16 mb-0"> Lead Designer </label>

        <div class="dropdown">
          <div
            type="text"
            class="btn dropdown-toggle d-flex border justify-content-between filter-opt4 dropdown1"
            required
            type="button"
            name="leadManager"
            id="leadManager"
            ngDefaultControl
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <span
              class="filter4 fs-18 fw-500 text-truncate"
              id="selectDesigner"
            >
              <div *ngIf="!isEditProject" class="edit-project-dropdown fw-500">
                Lead Designer
              </div>

              <div class="fw-600 text-dark" *ngIf="isEditProject">
                {{ initialProject?.lead_designer?.name }}
              </div>
            </span>
            <img src="assets/images/Path 61663.svg" class="order-1" />
          </div>
          <div
            class="dropdown-menu dropdown-menu-right filter-list4 border-0 shadow dropdown cursor-pointer dropdown-list custom-scroll-bar w-100"
            aria-labelledby="selectAccounter"
          >
            <input
              id="searchLeadDesigner"
              name="searchLeadDesigner"
              type="text"
              class="fs-14 form-control"
              placeholder="Search Lead Designer"
              [(ngModel)]="searchLeadDesigner"
              (ngModelChange)="searchLeadDesigners($event)"
            />
            <a
              class="dropdown-item hoverName"
              *ngFor="let x of userList"
              (click)="leadDesignerDropdown($event, x)"
              ><span>{{ x?.name + " " + x?.last_name }}</span></a
            >
          </div>
          <!-- <div class="invalid-feedback d-flex" *ngIf="leadDesignerError">
              Lead Manager Name is required.
            </div> -->
        </div>
      </div>

      <div class="form-group col-6">
        <label for="technical" class="fs-16 mb-0">
          Technical Merchandiser
        </label>

        <div class="dropdown">
          <div
            type="text"
            class="form-control fs-18"
            id="technical"
            name="technical"
            required
            class="btn dropdown-toggle d-flex border justify-content-between filter-opt5 dropdown1"
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
            ngDefaultControl
          >
            <span
              class="filter5 fs-18 fw-500 text-truncate"
              id="selectTechnicalmerch"
            >
              <div *ngIf="!isEditProject" class="edit-project-dropdown fw-500">
                Technical Merchandiser
              </div>

              <div class="fw-600 text-dark" *ngIf="isEditProject">
                {{ initialProject?.technical_merchandiser?.name }}
              </div>
            </span>
            <img src="assets/images/Path 61663.svg" class="order-1" />
          </div>
          <div
            class="dropdown-menu dropdown-menu-right filter-list5 border-0 shadow dropdown cursor-pointer custom-scroll-bar dropdown-list w-100"
            aria-labelledby="selectAccounter"
          >
            <input
              id="searchTechnical"
              name="searchTechnical"
              type="text"
              class="fs-14 form-control"
              placeholder="Search Technical Merchandiser"
              [(ngModel)]="searchTechnicalValue"
              (ngModelChange)="searchTechnicalMerchandiser($event)"
            />
            <a
              class="dropdown-item hoverName"
              *ngFor="let x of userList"
              (click)="technicalMerchandiseDropdown($event, x)"
              ><span>{{ x?.name + " " + x?.last_name }}</span></a
            >
          </div>
          <!-- <div
              class="invalid-feedback d-flex"
              *ngIf="technicalMarchandisesError"
            >
              Technical Merchandise Name is required.
            </div> -->
        </div>
      </div>

      <div class="form-group col-12">
        <label for="message" class="fs-16 mb-0">Message to Team</label>
        <textarea
          name="TextMessage"
          class="form-control py-3 fs-18"
          rows="3"
          id="message"
          placeholder="Enter Description"
          [(ngModel)]="initialProject.message_to_team"
          (keyup)="enableSave()"
          ngModel
          #TextMessage="ngModel"
        >
        </textarea>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-2">
      <button
        type="button"
        class="btn btn-light mr-3 py-2 fs-18 color-title-light"
        (click)="resetForm()"
      >
        Cancel
      </button>

      <button
        type="submit"
        class="btn py-2 fs-18 bg-main text-white d-flex align-items-center"
        [disabled]="!enableSubmit"
      >
        <img
          src="assets/images/add-file.svg"
          class="mr-1"
          style="width: 0.8rem"
          *ngIf="!isEditProject"
        />
        {{ isEditProject ? "Save Project" : "Create New Project" }}
      </button>
    </div>
  </form>
</div>
