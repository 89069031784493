<div class="wrapper overflow-hidden">
  <header>
    <div class="navbar navbar-default navbar-static-top py-2">
      <div class="container-fluid d-flex py-2">
        <div class="navbar-header col-xl-3 col-sm-3">
          <a class="navbar-brand mt-0"
            ><img src="../../assets/images/logo.svg" alt="logo"
          /></a>
          <button
            class="navbar-toggler d-none"
            type="button"
            data-toggle="collapse"
            data-target="navbar-collapse"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>

        <div class="col-xl-9 d-lg-flex col-sm-9 pr-sm-0 d-sm-none">
          <div class="col-xl-8 col-sm-7"></div>
          <div class="col-xl-4 col-sm-5 px-sm-0">
            <ul class="nav navbar-nav flex-lg-row justify-content-end">
              <li
                class="mx-1 header-login bg-main border-0 cursor-pointer d-flex align-items-center"
              >
                <a [routerLink]="'/auth'" class="text-white">Login</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="content">
    <form
      [formGroup]="forgetpassword"
      autocomplete="off"
      (ngSubmit)="onSubmit(); forgetpassword.reset()"
    >
      <div class="card bg-white w-75 mx-auto d-flex flex-row mt-4">
        <div class="col-xl-6 col-sm-12 mx-auto">
          <div class="card my-5 p-5">
            <div class="login-title">
              <h4 class="m-0 font-bold text-center">Forgot password</h4>
            </div>
            <div class="login-form">
              <div class="form-carry">
                <div class="single-row my-4 position-relative">
                  <label class="fs-18 fw-600">Enter Email-ID:</label>
                  <input
                    type="text"
                    class="form-control py-4 form"
                    placeholder="Enter Email-ID"
                    id="email"
                    [(ngModel)]="email"
                    formControlName="email"
                    name="email"
                    required
                  />
                  <div>
                    <div
                      *ngIf="
                        forgetpassword.controls.email.touched &&
                        forgetpassword.controls.email.invalid
                      "
                      class="invalid-feedback d-block error-msg"
                    >
                      Email ID is required
                    </div>
                  </div>

                  <!-- <div>
                      <div
                        *ngIf="
                          loginForm.controls.password.touched &&
                          loginForm.controls.password.invalid
                        "
                        class="invalid-feedback d-block error-msg"
                      >
                        Enter Valid password
                      </div>
                    </div> -->
                </div>
                <div class="h-blank"></div>
                <div class="single-row mt-3">
                  <button
                    class="login-btn btn btn-info bg-main text-white"
                    type="submit"
                    [disabled]="!forgetpassword.valid"
                  >
                    Reset Password
                  </button>
                  <button
                    [routerLink]="'/auth'"
                    class="btn bg-white text-thanks mt-2"
                    type="button"
                  >
                    No Thanks
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
