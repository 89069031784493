<div class="modal-header py-2">
  <h4 class="modal-title pull-left">Message</h4>
  <button
    type="button"
    (click)="close(statusMessage)"
    class="btn-close close pull-right"
    aria-label="Close"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">{{ statusMessage }}</div>

<div class="text-center mb-2">
  <button
    class="btn border-orange bg-main text-white mx-auto"
    (click)="close(statusMessage)"
  >
    Ok
  </button>
</div>
