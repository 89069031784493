import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRequestService } from 'src/app/common-services/http-request.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  showPassword: boolean;
  showConfrimPassword: boolean;
  password: any;
  confirmPassword: any;
  // token: any;
  token = this.routes.snapshot.queryParamMap.get('reset_password_token');
  constructor(
    private formBuilder: FormBuilder,
    private routes: ActivatedRoute,
    private router: Router,
    private http: HttpRequestService
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    try {
      let rqdata: any = {
        password: this.password,
        confirm_password: this.confirmPassword,
        token: this.token,
      };

      this.http
        .request('post', '/employee/user/resetPassword', rqdata)
        .subscribe((response: any) => {
          localStorage.clear();
          this.router.navigateByUrl('/auth');
        });
    } catch (error) {}
  }
}
