import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthInfoService } from 'src/app/common-services/auth-info.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  isSliderOpen: boolean = false;
  subscribe: any;

  constructor(private router: Router, private Auth: AuthInfoService) {}

  ngOnInit(): void {
    // Subsicribe Data of silder modal
    // this.subscribe = this.Auth.sliderNode$.subscribe((e: any) => {
    //   if (e == null) {
    //     return;
    //   }
    //   if (e.componentName == null) {
    //     return;
    //   }
    //   if (e.isShow != null) {
    //     this.isSliderOpen = e.isShow ?? false;
    //   }
    // });
  }
}
