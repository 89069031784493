import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber',
})
export class NumberPipe implements PipeTransform {
  transform(value: any, ...args: any): unknown {
    let newnum = value.split(/[\s,]+/);
    var newnum1 = newnum[1]
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/);

    newnum[1] = !newnum1[2]
      ? newnum1[1]
      : newnum1[1] +
        '-' +
        newnum1[2] +
        (newnum1[3] ? '-' + newnum1[3] + newnum1[4] : '');

    var newnum2 = newnum[0].replace(/\D/g, '').match(/(\d{0,4})/);
    newnum[0] = !newnum2[0] ? newnum2[0] : '+' + '(' + newnum2[0] + ') ';

    var newval = newnum[0] + newnum[1];

    return newval;
  }
}
