<div class="wrapper overflow-hidden">
  <header>
    <div class="navbar navbar-default navbar-static-top py-2">
      <div class="container-fluid d-flex py-2">
        <div class="navbar-header col-xl-3 col-sm-3">
          <a class="navbar-brand mt-0"
            ><img src="../../assets/images/logo.svg" alt="logo"
          /></a>
          <button
            class="navbar-toggler d-none"
            type="button"
            data-toggle="collapse"
            data-target="navbar-collapse"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>

        <div class="col-xl-9 d-lg-flex col-sm-9 pr-sm-0 d-sm-none">
          <div class="col-xl-8 col-sm-7"></div>
          <div class="col-xl-4 col-sm-5 px-sm-0">
            <ul class="nav navbar-nav flex-lg-row justify-content-end">
              <li
                class="mx-1 header-login bg-main border-0 cursor-pointer d-flex align-items-center"
              >
                <a [routerLink]="'/auth'" class="text-white">Login</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="content">
    <form #resetPassword="ngForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="card bg-white w-75 mx-auto d-flex flex-row mt-4">
        <div class="col-xl-6 col-sm-12 mx-auto">
          <div class="card my-4 p-5">
            <div class="login-title">
              <h4 class="m-0 font-bold text-center">Set password</h4>
            </div>
            <div class="login-form">
              <div class="form-carry">
                <div class="single-row my-4 position-relative">
                  <label class="fs-18 fw-600">Enter password:</label>
                  <input
                    type="password"
                    class="form-control py-4 form"
                    placeholder="Enter password"
                    id="password"
                    [(ngModel)]="password"
                    name="password"
                    [type]="showPassword ? 'text' : 'password'"
                    #Password="ngModel"
                    minlength="8"
                    required
                    password
                  />

                  <!-- <div>
                     [(ngModel)]="password"
                    name="password"
                    [type]="showPassword ? 'text' : 'password'"
                    #Password="ngModel"
                    required
                    password
                    <div
                      *ngIf="
                        loginForm.controls.password.touched &&
                        loginForm.controls.password.invalid
                      "
                      class="invalid-feedback d-block error-msg"
                    >
                      Enter Valid password
                    </div>
                  </div> -->
                </div>
                <div class="single-row mt-4 position-relative">
                  <label class="fs-18 fw-600">Confirm password:</label>
                  <input
                    type="password"
                    class="form-control py-4 form"
                    id="confirmPassword"
                    [(ngModel)]="confirmPassword"
                    name="confirmPassword"
                    placeholder="confirm password"
                    minlength="8"
                    #ConfirmPassword="ngModel"
                    [type]="showConfrimPassword ? 'text' : 'password'"
                    placeholder="***********"
                    required
                  />

                  <div class="toggler">
                    <img
                      src="../../assets/images/eye_close.svg"
                      class="form-icon-staus invalid-feedback d-block"
                      (click)="showConfrimPassword = !showConfrimPassword"
                      [class.hide]="showConfrimPassword"
                    />
                    <img
                      src="../../assets/images/eye_open.svg"
                      class="form-icon-staus invalid-feedback d-block hide"
                      (click)="showConfrimPassword = !showConfrimPassword"
                      [class.hide]="!showConfrimPassword"
                    />
                  </div>

                  <!-- <div>
                    <div
                      *ngIf="
                        loginForm.controls.confirmPassword.touched &&
                        loginForm.controls.confirmPassword.invalid
                      "
                      class="invalid-feedback d-block error-msg"
                    >
                      Password is required
                    </div>
                  </div> -->
                </div>
                <label for="" class="fs-13 invalid-feedback d-block error-msg"
                  >*Password should be of 8 or more characters</label
                >
                <div
                  class="mt-2"
                  *ngIf="resetPassword.submitted && password != confirmPassword"
                >
                  <div class="invalid-feedback d-block error-msg">
                    Password does not match
                  </div>
                </div>

                <div class="h-blank"></div>
                <div class="single-row mt-3">
                  <button
                    class="login-btn btn btn-info bg-main text-white"
                    type="submit"
                    [disabled]="
                      password != confirmPassword || !resetPassword.valid
                    "
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
