import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthInfoService } from 'src/app/common-services/auth-info.service';
import { CommonService } from 'src/app/common-services/common.service';
import { HttpRequestService } from 'src/app/common-services/http-request.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  email: any;
  constructor(
    private http: HttpRequestService,
    private router: Router,
    private Auth: AuthInfoService,
    private commonService: CommonService,
    private formBuilder: FormBuilder
  ) {}

  forgetpassword = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });

  ngOnInit(): void {}
  onSubmit() {
    try {
      let rqdata: any = {
        email: this.email,
      };

      this.http
        .request('post', '/employee/user/forgotPassword', rqdata)
        .subscribe((response: any) => {
          this.commonService.popUp('Reset Password Email Sent Successfully');
          this.router.navigateByUrl('/auth');
        });
    } catch (error) {
    }
  }
}
