<div
  class="modal fade"
  data-backdrop="static"
  [attr.id]="modalId"
  tabindex="-1"
>
  <div class="modal-dialog modal-dialog-centered" [ngClass]="modalSizeConfig">
    <div class="modal-content">
      <div class="modal-header border-0 pt-4 pb-0">
        <ng-content select="[header]"></ng-content>
      </div>
      <div class="modal-body mb-0">
        <ng-content select="[body]"></ng-content>
      </div>
      <div class="modal-footer border-0">
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  </div>

  <!-- <div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Modal title</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Modal body text goes here.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary">Save changes</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div> -->
</div>
